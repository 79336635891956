<template>
  <div class="started-header">
    <img src="@/assets/logo-color.png" alt="">
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class StartHeader extends Vue {}
</script>

<style lang="scss" scoped>
  .started-header {
    padding: 26px 0;
    text-align: center;
    background-color: var(--bg-primary-gray);

    @include media-xs-only {
      padding: 16px 0;
    }

    img {
      width: 304px;
      height: 26px;

      @include media-xs-only {
        width: 220px;
        height: 19px;
      }
    }
  }
</style>
