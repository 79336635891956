<template>
  <div class="list-container flex no-shirk item-center">
    <div v-if="taskStatus" class="flex item-center">
      <span
        v-if="taskStatus === 'init' || taskStatus === 'completed'"
        @click="handleFinishTask"
        class="prepend checkbox"
        :class="{ 'finish': taskStatus === 'completed' }"
      >
        <img v-if="taskStatus === 'completed'" src="@/assets/complete.png" alt="">
      </span>
      <span v-if="taskStatus === 'wait_advisor_review'" class="prepend">
        <i class="el-icon-time"></i>
      </span>
    </div>
    <div class="content flex">
      <component
        :is="to ? 'router-link' : 'div'"
        :to="to"
        class="text-overflow list-desc"
        :class="{ 'finish-link': taskStatus === 'completed' }"
        @click="$emit('click-link')"
      >
        {{ text }}
      </component>
      <span class="due-time self-end text-overflow">{{time}}</span>
    </div>
    <div class="tag-content">
      <slot name="tagSlot"/>
    </div>
    <component v-if="msgNum" class="msg" :is="to ? 'router-link' : 'div'" :to="to">
      <common-prod-msg :msgText="msgNum"/>
    </component>
    <common-prod-tag v-if="tagText" :tagText="tagText" :toggle="false" />
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class ListLine extends Vue {
    @Prop(String) text
    @Prop(String) time
    @Prop({ type: [String, Number] }) msgNum
    @Prop(String) taskStatus  // init, wait_advisor_review, completed
    @Prop(String) tagText
    @Prop([String, Object]) to

    async handleFinishTask() {
      const isAdvisor = this.$authStore.isAdvisor;

      if (isAdvisor && this.taskStatus === 'completed') {
        this.$message.warning('The assignment is definitely accomplished!');
        return;
      } else if (!isAdvisor && this.taskStatus !== 'init') {
        this.$message.warning('the assignment is done!');
        return;
      }

      const title = isAdvisor ? 'Confirmation' : 'Done?';
      const text = isAdvisor ? `Confirm that ${this.$get(this.$authStore, 'user.current_role.name')} has completed this assignment?` : 'Submit all files associated with this assignment to your advisor for review?';
      const cancelText = isAdvisor ? 'No, it still needs work' : 'No, I forgot something';
      await this.$confirm(text, title, {
        confirmButtonText: 'YES',
        cancelButtonText: cancelText
      });
      this.$emit('finish');
    }
  }
</script>

<style lang="scss" scoped>
  .list-container {
    flex-wrap: nowrap;

    @include media-lg-and-down {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      width: 100%;
    }

    .prepend {
      display: inline-block;
      margin-right: 33px;

      .el-icon-time {
        font-size: 25px;
        color: var(--font-seventh-purple);
      }
    }

    .checkbox {
      width: 17px;
      height: 17px;
      border: 2px solid var(--border-seventh-purple);
      background: transparent;
      transition: all .3s;
      cursor: pointer;

      &.finish {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--border-fifth-gray);
        text-align: center;
        vertical-align: middle;
        background: var(--bg-fifth-gray);

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .content {
      @include media-lg-and-down {
        display: block;
      }

      @include media-xs-only {
        width: 100%;
      }

      .list-desc {
        width: 350px;
        font-size: 24px;
        text-decoration: underline;
        cursor: pointer;

        @include media-lg-and-down {
          width: auto;
          text-overflow: ellipsis;
        }

        &.finish-link {
          text-decoration: line-through;
          color: var(--font-fifth-gray);
        }
      }

      .due-time {
        margin: 0 16px;
        font-size: 14px;
        color: var(--font-primary-blue);

        @include media-lg-and-down {
          display: inline-block;
          margin: 10px 0 0;
        }
      }
    }

    .tag-content {
      margin-right: 30px;
    }
  }
</style>
