<template>
  <div class="common-sidebar">
    <div class="side-wrap flex column">
      <router-link :to="{ name: 'home' }" class="logo hidden-xs-only">
        <img src="@/assets/atomicmind-apply-logo.svg" alt="" />
      </router-link>
      <div class="container flex-1 flex column" @click="handleShowTip">
        <router-link
          class="side-nav flex item-center"
          v-for="(item, index) in routerList"
          :key="index"
          :to="{ name: item.name }"
          :exact="item.name === 'home'"
        >
          <img class="nav-icon" :src="item.icon" alt="">
          <span class="nav-text hidden-xs-only">{{ item.text }}</span>
        </router-link>
      </div>
      <div class="side-footer content-end flex column hidden-xs-only">
        <div class="foot-icon flex">
          <img src="@/assets/sidebar/theme.png" alt="" @click="$toggleDarkTheme">
          <!-- <img src="@/assets/sidebar/present.png" alt=""> -->
        </div>
        <div class="foot-text">Copyright © {{ year }} AtomicMind. All rights reserved.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class CommonSideBar extends Vue {
    year = new Date().getFullYear()

    get routerList() {
      return [
        {
          name: 'home',
          icon: require('@/assets/sidebar/nav-1.png'),
          text: 'Dashboard',
        },
        {
          name: 'tasks',
          icon: require('@/assets/sidebar/nav-4.png'),
          text: 'Assignments',
        },
        {
          name: 'files',
          icon: require('@/assets/sidebar/nav-5.png'),
          text: 'Files',
        },
        {
          name: 'schools',
          icon: require('@/assets/sidebar/nav-2.png'),
          text: 'Schools',
        },
        // 隐藏schedule入口
        // !this.isStudent && {
        //   name: 'schedule',
        //   icon: require('@/assets/sidebar/nav-3.png'),
        //   text: 'Schedule',
        // },
        !this.isStudent && {
          name: 'students',
          icon: require('@/assets/sidebar/nav-6.png'),
          text: 'All Students',
        }
      ].filter(Boolean);
    }

    get isStudent() {
      return this.$get(this.$authStore, 'user.role') === this.$get(this.$authStore, 'user.current_role.role');
    }

    get currentRouter() {
      let path = this.$get(this.$route, 'path');
      if (path === '/') {
        return '/home';
      } else if (path.lastIndexOf('/') <= 0) {
        return path;
      }
      return path.substring(0, String(path).lastIndexOf('/'));
    }

    // 如果学生没有 advisor 提示去联系 team
    handleShowTip() {
      if (this.$authStore.isUnpaidStudent) {
        this.$alertMsg('You have not been assigned a Head Advisor yet, please contact our staff.');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .common-sidebar {
    box-sizing: border-box;
    width: 300px;
    height: 100vh;
    padding: 26px 42px;
    color: var(--font-primary-white);
    background: var(--linear-gradient-purple);

    @include media-xs-only {
      box-sizing: content-box;
      width: 100%;
      height: 48px;
      padding: 5px 0;
      background: var(--bg-seventh-purple);

      @include paddingBottomSafeArea(5px);
    }

    .side-wrap {
      height: 100%;

      .logo {
        margin-bottom: 70px;
        cursor: pointer;

        img {
          width: 200px;
          height: auto;
        }
      }

      .container {
        height: 100%;
        text-align: center;

        @include media-xs-only {
          flex-direction: row;
          justify-content: space-around;
          width: 100%;
        }

        .side-nav {
          margin-bottom: 47px;
          font-size: 18px;
          opacity: 0.68;

          @include media-xs-only {
            margin-bottom: 0;
          }

          &.router-link-active {
            font-family: 'Neue Montreal Bold';
            opacity: 1;
          }

          .nav-icon {
            width: 20px;
            height: 20px;

            @include media-xs-only {
              width: 26px;
              height: 26px;
            }
          }

          .nav-text {
            margin-left: 18px;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }
    }

    .side-footer {
      text-align: left;

      .foot-icon {
        margin-bottom: 15px;

        img {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }

        img + img {
          margin-left: 20px;
        }
      }

      .foot-text {
        font-family: 'Neue Montreal Regular';
        line-height: 17px;
        opacity: 0.5;
      }
    }
  }
</style>
