import { Collection } from './helper/collection';
import { request } from '@/utils';

export class StudentsStore extends Collection {
  fetch(params) {
    return request.get('mine/related_users', { params });
  }
}

export const studentsStore = StudentsStore.create();
