<template>
  <common-prod-input type="onlySelect" :inputTitle="inputTitle" :autoHeight="autoHeight">
    <template v-slot:selectInput>
      <el-select
        ref="selectRef"
        v-model="selectValue"
        :placeholder="placeholder"
        :popper-append-to-body="false"
        :multiple="multiple"
        :collapse-tags="multiple"
        filterable
        @change="handleChange"
        @input="handleInput"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="$get(item, name) || item.name"
          :value="item[valueKey] || item.id"
        >
        </el-option>
      </el-select>
    </template>
    <template v-if="$slots.suffix" v-slot:suffix>
      <slot name="suffix"/>
    </template>
  </common-prod-input>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

  @Component
  export default class Select extends Vue {
    @Prop({ type: [String, Array, Number], default: null }) value
    @Prop({ type: String, default: '' }) inputTitle
    @Prop({ type: String, default: '' }) placeholder
    @Prop({ type: String, default: '' }) valueKey // select input 的 value 属性
    @Prop({ type: String, default: '' }) name // select input 的 name 属性
    @Prop({ type: Array, default: () => [] }) options
    @Prop({ type: Boolean, default: false }) multiple
    @Prop({ type: Boolean, default: false }) autoHeight

    selectValue = null;
    @Watch('value', { immediate: true })
    valueChange(val) {
      this.selectValue = val;
    }
    @Watch('selectValue', { immediate: true })
    watchSelectValue(val) {
      this.$emit('input', val);
    }

    async handleChange(val) {
      // 多选时，点击选项后，利用blur事件，让select失去焦点，从而让select的下拉框收起
      if (this.multiple) {
        await this.$nextTick();
        this.$refs.selectRef.blur();
      }
      this.$emit('change', val);
    }

    handleInput(val) {
      this.$emit('input', val);
    }
  }
</script>

<style lang="scss" scoped>
</style>
