<template>
  <div class="files">
    <div class="page-title">{{ pageTitle }}</div>
    <div v-if="keyword && filesStore.isEmpty" class="tip">
      {{ `No results related to "${keyword}" were found` }}
    </div>
    <template v-else>
      <common-prod-filter-groups :groups="filterGroups"/>
      <common-prod-button border @click.native="showFileForm = true">
        UPLOAD A FILE
      </common-prod-button>
      <div v-if="filesStore.isEmpty" class="empty-wrap">{{`You have no ${emptyText} files`}}</div>
      <common-prod-file-lists class="list-page" :files="filesStore.data" @delete-item="handleDeleteFile" showTag/>
      <common-prod-pagination :store="filesStore"/>
    </template>
    <common-popup v-model="showFileForm" title="Upload a file">
      <div class="file-form">
        <common-prod-select
          v-model="taskCategoriesArray"
          inputTitle="Add a category (Optional)"
          placeholder="Select category"
          :options="categoriesStore.data"
          multiple
          autoHeight
        />
        <common-prod-select
          v-model="schoolStudentArray"
          inputTitle="Add a school (Optional)"
          placeholder="Select school"
          :options="schoolStudentsStore.data"
          name="school.name"
          multiple
          autoHeight
        />
        <div class="file-input-wrapper">
          <common-prod-upload-input v-model="files"/>
        </div>
        <common-prod-button
          class="submit-comment-btn"
          :disabled="disabled"
          @click.native="handleUpload"
        ><span class="uppercase">UPLOAD</span></common-prod-button>
      </div>
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { settingsStore, schoolStudentsStore, categoriesStore } from '@/stores';
  import { getQueryArray } from '@/utils';
  import _ from 'lodash';

  @Component
  export default class Files extends Vue {
    disabled = false
    showFileForm = false
    taskCategoriesArray = []
    schoolStudentArray = []
    files = []
    categoriesStore = categoriesStore
    settingsStore = settingsStore
    schoolStudentsStore = schoolStudentsStore
    filesStore = this.$Collection.create({
      fetch: params => {
        return this.$request.get('documents', { params });
      }
    })

    get keyword() {
      return this.$route.query.keyword;
    }

    get pageTitle() {
      if (this.keyword) {
        return `Search "${this.keyword}" results`;
      }
      let name;
      if (this.$authStore.isAdvisor) {
        name = this.$get(this.$authStore, 'user.students.length') ? `${this.$get(this.$authStore, 'user.current_role.name', '')}'s` : 'Your student\'s';
      } else {
        name =  'Your';
      }
      return `${name} Files`;
    }

    get emptyText() {
      let emptyArr = [];
      let label = '';
      getQueryArray('type_in').forEach(value => {
        Object.keys(this.settingsStore.files_mine_type).forEach(key => {
          if (key === value) {
            switch (key) {
              case 'docx':
                label = 'word';
                break;
              case 'xlsx':
                label = 'excel';
                break;
              default:
                label = key;
                break;
            }
            emptyArr.push(label);
          }
        });
      });
      getQueryArray('document_taggings_taggable_of_SchoolStudent_type_id_in').forEach(id => {
        let item = this.schoolStudentsStore.data.find((item) => item.id === Number(id));
        emptyArr.push(this.$get(item, 'school.name'));
      });
      getQueryArray('document_taggings_taggable_of_TaskCategory_type_id_in').forEach(id => {
        let item = this.categoriesStore.data.find((item) => item.id === Number(id));
        emptyArr.push(this.$get(item, 'name'));
      });
      return [...emptyArr].join(' and ');
    }

    get filterGroups() {
      const types = _.map(this.settingsStore.files_mine_type, (val, key) => {
        switch (key) {
          case 'others':
            return { label: this.$options.filters.firstUpperCase(key), value: key };
          case 'docx':
            return { label: 'Word', value: key };
          case 'xlsx':
            return { label: 'Excel', value: key };
          default:
            return { label: key.toUpperCase(), value: key };
        }
      });
      const schools = _.map(this.schoolStudentsStore.data, item => {
        return {
          label: item.school.name,
          value: item.id
        };
      });
      const taskCategories = _.map(this.categoriesStore.data, item => {
        return {
          label: item.name,
          value: item.id
        };
      });

      return [
        {
          key: 'type',
          title: 'By Type',
          allBtn: true,
          data: types,
        },
        {
          key: 'document_taggings_taggable_of_SchoolStudent_type_id',
          title: 'By School',
          allBtn: true,
          data: schools,
        },
        {
          key: 'document_taggings_taggable_of_TaskCategory_type_id',
          title: 'By Category',
          allBtn: true,
          data: taskCategories,
        },
      ];
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.fetchData();
    }

    async fetchData() {
      this.filesStore.setQueryToParams([
        'keyword',
        'type_in',
        'document_taggings_taggable_of_SchoolStudent_type_id_in',
        'document_taggings_taggable_of_TaskCategory_type_id_in'
      ]);
      await Promise.all([
        this.$settingsStore.tryFetchData(),
        this.filesStore.fetchData(),
        this.schoolStudentsStore.fetchData(),
        this.categoriesStore.fetchData(),
      ]);
    }

    async handleUpload() {
      const attachments = [];
      let uploadData = [];
      this.files.map(item => {
        if (!item._destroy) {
          attachments.push(item.attachment);
        }
      });
      if (!attachments.length) {
        return this.$message({ message: 'Please upload a file', type: 'error' });
      }

      const document_taggings_attributes = [
        ...this.taskCategoriesArray.map(id => ({ taggable_type: 'TaskCategory', taggable_id: id })),
        ...this.schoolStudentArray.map(id => ({ taggable_type: 'SchoolStudent', taggable_id: id }))
      ];
      const { data } = await this.$autoLoading(this.$request.post('documents', { attachments, document_taggings_attributes }));
      uploadData = data;

      uploadData.map(item => this.filesStore.unshift(item));
      this.showFileForm = false;
      this.files = [];
    }

    // 删除文件
    @Vue.autoLoading
    async handleDeleteFile(file) {
      await this.$request.delete(`documents/${file.id}`);
      this.filesStore.removeItemById(file.id);
    }
  }
</script>

<style lang="scss" scoped>
  .tip {
    margin-bottom: 80px;
    font-size: 36px;
    color: var(--font-secondary-black);
  }

  .file-form {
    width: 500px;

    @include media-xs-only {
      width: auto;
    }

    > div + div {
      margin-top: 30px;
    }
  }

  /deep/.el-scrollbar {
    max-height: 180px;
  }

  .empty-wrap {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
  }

  /deep/ .el-icon-close {
    font-size: 20px;
    cursor: pointer;
  }
</style>
