<template>
  <div class="input flex column">
    <span v-if="inputTitle" class="input-name"><span class="star" v-if="required">* </span>{{ inputTitle }}</span>
    <common-vditor style="height: 15.73977rem;" v-model="inputLabel"  v-if="type === 'richtext'"/>
    <div v-else class="input-wrap flex item-left" :class="{ 'auto-height': autoHeight }">
      <img class="prefix-icon icon" v-if="prefixIcon" mode="widthFix" :src="prefixIcon" @click="handleClickIcon"/>
      <div v-if="$slots.selectInput" class="select-wrap" :class="{only: type !== 'onlySelect'}">
        <slot name="selectInput"/>
      </div>
      <input
        ref="input"
        :readonly = readonly
        v-if="type !== 'onlySelect'"
        class="flex-1"
        :type="isType"
        :placeholder="placeholder"
        v-model="inputLabel"
        @blur="handleBlur"
        @focus="handleFocus"
        :autocomplete="autoComplete ? 'on' : 'new-password'"
        @change="handleChange"
      />
      <!-- password icon -->
      <div class="flex item-center">
        <img class="suffix-icon icon" v-if="isSuffixIcon" mode="widthFix" :src="isSuffixIcon" @click="handleClickIcon"/>
      </div>
      <!-- suffix slot -->
      <div v-if="$slots.suffix" class="flex item-center suffix-icon">
        <slot name="suffix"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

  @Component
  export default class Input extends Vue {
    /* 事件
      click-icon 点击图标发送事件
      input 在 Input 值改变时触发
      blur 在 在 Input 获得焦点时触发
      focus 在 Input 值改变时触发
      change 仅在输入框失去焦点或用户按下回车时触发
    */
    @Prop({ type: String, default: '' }) placeholder
    @Prop({ type: String, default: 'text' }) type //onlySelect：不用拼接的选择框（时区、国家); 和其他 原生 input 的 type 值
    @Prop({ type: String, default: '' }) value //与父组件数据的v-model数据传递 绑定值
    @Prop({ type: String, default: '' }) suffixIcon // 插入组件后面的icon
    @Prop({ type: String, default: '' }) prefixIcon // 插入组件前面的icon
    @Prop({ type: String, default: '' }) inputTitle // input组件的标题
    @Prop({ type: Boolean, default: false }) readonly // 只读模式
    @Prop({ type: Boolean, default: false }) focus  // 自动获取焦点
    @Prop({ type: Boolean, default: false }) required  // 必须填的input框
    @Prop({ type: Boolean, default: false }) autoHeight  // 高度自适应
    @Prop({ type: Boolean, default: false }) autoComplete

    inputLabel = {}
    hiddenIcon = true

    // 密码可见与不可见
    get isSuffixIcon() {
      if (this.type === 'password') {
        if (this.inputLabel) {
            return this.hiddenIcon ? require('@/assets/icon-hidden.png') : require('@/assets/icon-oppen.png');
        } else {
          return '';
        }
      } else {
        return this.suffixIcon;
      }
    }

    // 密码可见与不可见
    get isType() {
      if (this.type === 'password') {
        return this.hiddenIcon ? 'password' : 'text';
      } else {
        return this.type;
      }
    }

    @Watch('value', { immediate: true })
    valueChange(val) {
      this.inputLabel = val;
    }

    @Watch('inputLabel', { immediate: true })
    inputLabelChange(val) {
      this.inputLabel = val;
      this.$emit('input', val);
    }

    mounted() {
      if (this.type !== 'onlySelect' && this.focus) {
        this.$refs.input.focus();
      }
    }

    handleClickIcon() {
      if (this.type === 'password') {
        return this.hiddenIcon = !this.hiddenIcon;
      }
      this.$emit('click-icon');
    }

    handleBlur() {
      this.$emit('blur');
    }

    handleFocus() {
      this.$emit('focus');
    }

    handleChange() {
      this.$emit('change');
    }
  }
</script>

<style lang="scss" scoped>
.input-name {
  display: inline-block;
  padding: 0 0 6px 17px;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: var(--font-secondary-black);
}

.star {
  color: $primary-red;
}

.input-wrap {
  box-sizing: border-box;
  height: 64px;
  border: 3px solid var(--border-primary-blue);

  &.auto-height {
    height: auto;
  }

  .select-wrap {
    width: 100%;

    &.only {
      display: inline-block;
      width: 150px;
    }
  }
}

input {
  width: 100%;
  padding: 0 20px;
  border: none;
  outline: none;
  font-size: 24px;
  color: var(--font-secondary-black);
  background-color: transparent;

  &::placeholder {
    color: var(--font-secondary-gray);
  }
}

.prefix-icon {
  padding-left: 20px;
}

.suffix-icon {
  padding-right: 20px;
}

.icon {
  width: 35px;
  cursor: pointer;
}

/deep/ .el-select {
  width: 100%;
  height: 100%;
  text-align: left;

  .el-input {
    height: 100%;
  }

  .el-input__inner {
    height: 100%;
    border: none;
    overflow: hidden;
    font-size: 1.25918rem;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--font-secondary-black);
    background: transparent;
  }

  .el-select-dropdown {
    position: absolute !important;
    width: 100%;
    border: 0.1574rem solid var(--border-primary-blue);

    &.is-multiple {
      .el-select-dropdown__item {
        &.hover {
          color: var(--font-primary-white);
          background-color: var(--bg-primary-purple);
        }
      }
    }
  }

  .el-select-dropdown__empty {
    font-size: 1.25918rem;
    color: var(--font-secondary-black);
  }

  .el-select-dropdown__wrap {
    .selected {
      color: var(--font-primary-white);
      background: var(--bg-primary-purple);
    }
  }

  .el-icon-arrow-up:before {
    font-weight: 600;
    color: var(--font-primary-black);
  }

  .popper__arrow {
    display: none;
  }

  .el-scrollbar {
    li {
      font-size: 1.25918rem;
      color: var(--font-secondary-black);

      &:hover {
        color: var(--font-primary-white);
        background-color: var(--bg-primary-purple);
      }
    }

    @include media-xs-only {
      max-height: 148px;
    }
  }
}
</style>
