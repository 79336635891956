<template>
  <div
    class="ato-button"
    :disabled="disabled"
    :class="[
      buttonSize ? 'ato-button--' + buttonSize : '',
      { 'is-border': border },
      { 'is-disabled': disabled }
    ]"
  >
    <strong v-if="$slots.default">
      <slot/>
    </strong>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class CommonButton extends Vue {
    @Prop({ type: String, default: 'small' }) size
    @Prop(Boolean) disabled
    @Prop(Boolean) border

    get buttonSize() {
      return this.size;
    }
  }
</script>

<style lang="scss" scoped>
  .ato-button {
    display: inline-block;
    font-family: 'Neue Montreal Regular';
    text-align: center;
    text-transform: uppercase;
    color: var(--font-primary-white);
    background-color: var(--bg-primary-red);
    cursor: pointer;

    &.ato-button--small {
      min-width: 140px;
      height: 20px;
      padding: 9px 16px 7px;
      font-size: 18px;
      line-height: 20px;
    }

    &.ato-button--large {
      min-width: 220px;
      height: 28px;
      padding: 13px 32px 11px;
      font-size: 24px;
      line-height: 28px;
    }

    &.is-border {
      border: 1px solid var(--border-third-gray);
    }

    &.is-disabled {
      background-color: var(--bg-secondary-gray);
      cursor: not-allowed;
      pointer-events: none;
    }
  }
</style>
