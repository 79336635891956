<template>
  <transition name="el-fade-in">
    <div v-show="show" class="msg-card">
      <div class="mask" @click="$emit('input', false)"></div>
      <div class="msg-wrap">
        <div class="title flex item-center content-center">
          <span>Message</span>
          <img @click="$emit('input', false)" class="self-end" src="@/assets/close.png" alt="">
        </div>
        <div class="container">
          <common-msg-list :store="store"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { Vue, Component, Model, Prop } from 'vue-property-decorator';

  @Component
  export default class MessageList extends Vue {
    @Model('input', Boolean) show
    @Prop(Object) store
  }
</script>

<style lang="scss" scoped>
  .msg-card {
    position: absolute;
    z-index: 1001;
    right: 0;
    box-sizing: border-box;
    width: 332px;
    color: var(--font-primary-black);

    .msg-wrap {
      position: fixed;
      z-index: 999;
      box-sizing: border-box;
      padding: 16px;
      background: var(--bg-primary-gray);
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.23);

      .title {
        position: relative;
        padding-bottom: 14px;
        border-bottom: 1px solid var(--border-secondary-gray);
        font-weight: 600;

        img {
          position: absolute;
          right: 0;
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }

      .container {
        min-width: 252px;
        max-height: calc(100vh - 170px);
        padding-top: 16px;
        overflow: auto;
        font-size: 14px;
      }
    }
  }

  .mask {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
