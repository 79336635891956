<template>
  <transition name="el-fade-in">
    <div class="mask-wrap flex content-center item-center" v-if="showVdedio">
      <img src="@/assets/gray-close.png" class="icon" @click="handleClose"/>
      <iframe
        class="guide-vedio"
        :src="this.$settingsStore.tutorial_video_url"
        allow="accelerometer; autoplay;"
        allowfullscreen
      ></iframe>
    </div>
  </transition>
</template>

<script>
  import { Component, Vue, Model, Watch } from 'vue-property-decorator';

  @Component
  export default class GuideVedio extends Vue {
    @Model('input', Boolean) showVdedio;

    @Watch('showVdedio', { immediate: true })
    showVdedioChange(val) {
      document.body.classList[val ? 'add' : 'remove']('disabled-scrolling');
    }

    handleClose() {
      this.$emit('input', false);
    }
  }
</script>

<style lang="scss" scoped>
  .mask-wrap {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);

    .guide-vedio {
      position: relative;
      width: 80%;
      height: 80%;
    }

    .icon {
      position: absolute;
      z-index: 999;
      top: 6%;
      right: 7%;
      width: 24px;
      height: 24px;
      padding: 6px;
      cursor: pointer;

      @include media-xs-only {
        top: 6%;
        right: 4%;
        width: 16px;
        height: 16px;
      }
    }
  }
</style>
