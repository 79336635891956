import Hotjar from '@hotjar/browser';

const { VUE_APP_HOTJAR_SITE_ID } = process.env;
const hotjarVersion = 6;

const newHotjar = VUE_APP_HOTJAR_SITE_ID ? Hotjar : {
  identify: () => {},
  init: () => {}
};

newHotjar.init(VUE_APP_HOTJAR_SITE_ID, hotjarVersion);
export default newHotjar;
