<template>
  <div>
    <div ref="onlyRead" class="only-read" v-if="onlyRead" @click="handleClick"></div>
    <div ref="markdownEditor" class="editor" v-else></div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
  import Vditor from 'vditor';
  import { uploadFile } from '@/utils';
  import _ from 'lodash';

  const toolbar = [
    'headings',
    'bold',
    'italic',
    'strike',
    // 'link',
    '|',
    'list',
    'ordered-list',
    'check',
    '|',
    'quote',
    'line',
    'code',
    'inline-code',
    '|',
    'upload',
    // 'table',
    // '|',
    // 'undo',
    // 'redo',
    // '|',
    // 'fullscreen',
    // 'edit-mode',
  ];

  @Component
  export default class MarkdownEditor extends Vue {
    @Model('input', { type: String, default: '' }) value
    @Prop(Boolean) onlyRead

    @Watch('value', { immediate: true })
    async valueChange(val) {
      // content 为了防止没必要的 setValue 操作，光标被移动到最前面
      if (this.$vditor && this.content !== val) {
        this.$vditor.setValue(val || '');
        this.content = val;
      } else if (this.onlyRead) {
        this.initPreview(val);
      }
    }

    @Watch('$isDarkTheme', { immediate: true })
    themeChange() {
      if (this.$vditor) {
        this.$vditor.setTheme(this.$isDarkTheme ? 'dark' : 'classic', this.$isDarkTheme ? 'dark' : 'light');
      } else {
        Vditor.setContentTheme(this.$isDarkTheme ? 'dark' : 'light', 'https://cdn.jsdelivr.net/npm/vditor@3.8.6/dist/css/content-theme');
      }
    }

    async mounted() {
      if (this.onlyRead) {
        this.initPreview();
      } else {
        await this.initVditor();
      }
    }

    async initPreview(val) {
      const value = val || this.value || '';
      if (!this.onlyRead) {
        return;
      }
      await this.$nextTick();
      await this.$vditorPreviewPromise;
      this.$vditorPreviewPromise = new Promise(resolve => {
        Promise.resolve(this.$vditorPreviewPromise).then(() => {
          Vditor.preview(this.$refs.onlyRead, value, {
            markdown: {
              autoSpace: true,
            },
            theme: this.$isDarkTheme ? 'dark' : 'light',
            after: () => {
              this.$images = _.map(this.$refs.onlyRead.querySelectorAll('img'), img => img.src);
              resolve();
            },
          });
        });
      });
      return this.$vditorPreviewPromise;
    }

    handleClick(e) {
      const src = _.get(e.target, 'src');
      // 设置链接通过 new tab 打开
      if (e.target.tagName === 'A') {
        e.preventDefault();
        window.open(e.target.href);
      }

      if (src) {
        // TODO: 等待对接预览图片
        // const index = this.$images.indexOf(src);
        // const images = index === -1 ? [src] : this.$images;
        // this.$previewImage(images, index === -1 ? 0 : index);
      }
    }

    initVditor() {
      return new Promise((resolve) => {
        const vditor = new Vditor(this.$refs.markdownEditor, {
          lang: 'en_US',
          toolbar,
          counter: {
            enable: true,
            type: 'markdown',
          },
          value: this.value || '',
          theme: this.$isDarkTheme ? 'dark' : 'classic',
          preview: { theme: { current: this.$isDarkTheme ? 'dark' : 'light' } },
          cache: 'markdownEditor',
          upload: {
            headers: {
              Authorization: this.$authStore.accessToken
            },
            accept: 'image/*',
            handler: (...ags) => this.uploadImage(...ags),
            linkToImgUrl: '/api/v1/oss/replace_url',
            linkToImgFormat: res => `{ "data": ${res}, "code": 0 }`
          },
          after: () => {
            this.$vditor = vditor;
            vditor.blur();  // vditor 初始化后会自动聚焦，先手动 blur 处理一下
            Object.keys(vditor.vditor).map(item => {
              Object.defineProperty(vditor, item, {
                get: () => vditor.vditor[item]
              });
            });
            resolve(vditor);
            this.$emit('loaded', vditor);
          },
          input: value => this.emitContent('input', value),
          focus: value => this.emitContent('focus', value),
          blur: value => this.emitContent('blur', value),
        });
      });
    }

    emitContent(event, data) {
      data = data === '\n' ? '' : data;
      this.content = data;
      event && this.$emit(event, data);
    }

    async uploadImage(files) {
      files.forEach(async file => {
        try {
          const { url } = await uploadFile(file);
          this.$vditor.focus();
          this.$vditor.insertValue(`![](${url})`);
        } catch (e) {
          this.$vditor.tip.show(e.message);
          // eslint-disable-next-line no-console
          console.error(e);
        }
      });
    }
  }
</script>

<style lang="scss">
  @import '~vditor/src/assets/scss/index.scss';
</style>

<style lang="scss" scoped>
  .editor {
    height: 100%;
    min-height: 300px;
    max-height: 500px;
    border: 3px solid var(--border-primary-blue);
    border-radius: 0;
  }

  .only-read {
    overflow: hidden;

    /deep/ a {
      text-decoration: underline;
      color: var(--font-primary-purple);
    }
  }
</style>
