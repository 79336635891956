<template>
  <div class="schools">
    <div class="page-title">{{ titleName }} Schools</div>
    <el-row :gutter="24">
      <el-col
        :sm="12"
        :xs="24"
        v-for="(item, index) in schoolGroups"
        :key="index"
      >
        <common-prod-card :title="item.type === 'consider' ? considerSchoolsTitle : applySchoolsTitle">
          <SchoolLists :store="item.store" :isConsider="item.type === 'consider'"/>
        </common-prod-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import SchoolLists from './components/school-lists';
  import { SchoolStudentsStore } from '@/stores';

  @Component({
    components: {
      SchoolLists
    }
  })
  export default class Schools extends Vue {
    schoolGroups = [
      {
        store: SchoolStudentsStore.create({ params: { is_target_eq: false, page: 0 } }),
        type: 'consider'
      },
      {
        store: SchoolStudentsStore.create({ params: { is_target_eq: true, page: 0 } }),
        type: 'apply'
      },
    ]

    get titleName() {
      if (this.$authStore.isAdvisor) {
        return this.$get(this.$authStore, 'user.students.length') ? `${this.$get(this.$authStore, 'user.current_role.name')}'s` : 'Your student\'s';
      } else {
        return 'Your';
      }
    }

    get applySchoolsTitle() {
      if (this.$authStore.isAdvisor) {
        return `Schools ${this.$get(this.$authStore, 'user.current_role.name') || 'your student'} is Applying To`;
      } else {
        return 'Schools I’m Applying To';
      }
    }

    get considerSchoolsTitle() {
      if (this.$authStore.isAdvisor) {
        return `Schools ${this.$get(this.$authStore, 'user.current_role.name') || 'your student'} is Considering`;
      } else {
        return 'Schools I’m Considering';
      }
    }

    @Vue.autoLoadingProgress
    async created() {
      await Promise.all(this.schoolGroups.map(item => item.store.fetchData()));
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .card {
    .title + .body {
      padding: 24px 0 0;
    }
  }
</style>
