<template>
  <div class="school-detail">
    <div class="head flex content-between">
      <div class="left">
        <div class="school-name">{{ $get(schoolStudent, 'school.name') }}</div>
        <div class="tips">
          <router-link :to="{ name: 'tasks', query: { school_student_id_in: schoolStudentId } }" class="first">{{ $get(schoolStudent, 'active_tasks_count') | pluralize('active assignment', true, 'No') }}</router-link>
          <router-link :to="{ name: 'files', query: { school_student_id_in: schoolStudentId } }">{{ $get(schoolStudent, 'documents_count') | pluralize('file', true, 'No') }}</router-link>
        </div>
      </div>
      <!-- 学生不能删除学校 -->
      <div class="right">
        <div v-if="$authStore.isAdvisor" class="btn remove-btn" @click="handleDeleteSchool">Remove from {{ btnText }}{{ isTarget ? '' : 's' }}</div>
        <div v-else class="btn">{{ btnText | upperFirst }}</div>
      </div>
    </div>

    <div class="content" v-for="store in [tasksStore, filesStore]" :key="store.name">
      <div class="flex content-between">
        <div class="title">
          {{ store.name | upperFirst }}<span> ({{ store.name === 'tasks' ? $get(schoolStudent, 'tasks_count') : $get(schoolStudent, 'documents_count') }})</span>
        </div>
        <div v-if="store.name === 'tasks'" @click="handleFilter" class="filter-btn flex item-center content-center">{{ isFilterText }}</div>
      </div>
      <div class="empty-tip" v-if="store.isEmpty">
        <div v-if="store.name === 'files'">There are no files at present</div>
        <div v-else>
          <span v-if="isFilterText === 'show completed assignments'">There are no activity assignments at present</span>
          <span v-else>All done!</span>
        </div>
      </div>
      <div v-else-if="store.name === 'tasks'">
        <common-prod-list-line
          class="item"
          v-for="item in store.data"
          :key="item.id"
          :time="`(Due ${$options.filters.formatTime(item.due_at)})`"
          :text="$get(item, 'name')"
          :msgNum="item.comments_count"
          :to="{name: 'task.detail', params: { id: item.id }}"
          :taskStatus="item.status"
          @finish="finishTask(item)"
        />
      </div>
      <common-prod-file-lists v-else :files="store.data" @delete-item="handleDeleteFile"/>
      <div class="more" v-if="!store.isEmpty">
        <span v-if="store.isComplete" class="no-more">No More</span>
        <span v-else class="text" @click="handleLoadMore(store)">Load More</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component({
    filters: {
      upperFirst: _.upperFirst
    }
  })
  export default class SchoolDetail extends Vue {
    tasksStore = null
    filesStore = null
    schoolStudent = {}
    isFilter = false
    schoolStudentId = ''

    get isTarget() {
      return this.$get(this.schoolStudent, 'is_target');
    }

    get btnText() {
      if (this.isTarget) {
        return this.$authStore.isAdvisor ? `${this.$get(this.$authStore, 'user.current_role.name')}'s applying list` : 'My applying list';
      } else {
        return 'possible school';
      }
    }

    // 如果是隐藏了已完成任务，则按钮需要显示已完成任务的数量
    get isFilterText() {
      return this.isFilter ? `show activity assignments (${ this.$get(this.schoolStudent, 'active_tasks_count') })` : 'show completed assignments';
    }

    @Vue.autoLoadingProgress
    async created() {
      this.schoolStudentId = this.$route.params.id;
      const createStore = (api, name) => this.$Collection.create({
        name: name || api,
        params: { school_student_id_in: [this.schoolStudentId], per_page: 5 },
        fetch: params => this.$request.get(api, { params })
      });
      this.tasksStore = createStore('tasks');
      this.filesStore = createStore('documents', 'files');

      const [{ data }] = await Promise.all([
        this.$request.get(`school_students/${this.schoolStudentId}`),
        this.tasksStore.fetchData(),
        this.filesStore.fetchData(),
      ]);
      this.schoolStudent = data;
    }

    async handleDeleteSchool() {
      await this.$confirm(`The assignment data is also deleted after the deletion. Are you sure you want to remove from the ${this.btnText}${this.isTarget ? '' : 's'}?`);
      await this.$autoLoading(this.$request.delete(`school_students/${this.$route.params.id}`));
      await this.$message({ message: 'Operation is successful!', type: 'success' });
      this.$router.replace({ name: 'schools' });
    }

    @Vue.autoLoadingProgress
    handleFilter() {
      this.isFilter = !this.isFilter;
      this.isFilter ? Object.assign(this.tasksStore.params, { completed_at_not_null: true }) : delete this.tasksStore.params.completed_at_not_null;
      this.tasksStore.fetchData();
    }

    @Vue.autoLoading
    handleLoadMore(store) {
      return store.fetchMoreData({ per_page: 25 });
    }

    async finishTask(item) {
      try {
        if (this.$authStore.isAdvisor) {
          item.status = 'completed';
          await this.$request.put(`tasks/${item.id}/advisor_complete`);
        } else {
          item.status = 'wait_advisor_review';
          await this.$request.put(`tasks/${item.id}/student_complete`);
        }
      } catch (e) {
        this.$message.error(e.message);
      }
      const { data: schoolStudent } = await this.$request.get(`school_students/${this.schoolStudentId}`);
      this.schoolStudent = schoolStudent;
    }

    // 删除文件
    @Vue.autoLoading
    async handleDeleteFile(file) {
      await this.$request.delete(`documents/${file.id}`);
      this.filesStore.removeItemById(file.id);
    }
  }
</script>

<style lang="scss" scoped>
  .head {
    margin-bottom: 48px;

    @include media-xs-only {
      flex-direction: column;
      margin-bottom: 24px;
    }

    .school-name {
      padding-right: 40px;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: var(--font-primary-black);
    }

    .tips {
      padding-top: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--font-forth-blue);

      .first {
        padding-right: 33px;
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
      padding: 0 40px;
      border: 1px solid var(--border-secondary-gray);
      border-radius: 17px;
      color: var(--font-forth-gray);

      &.remove-btn {
        color: var(--font-primary-white);
        background: var(--bg-seventh-purple);
        cursor: pointer;

        @include media-sm-and-up {
          &:hover {
            opacity: 0.8;
          }
        }
      }

      @include media-xs-only {
        margin-top: 20px;
      }
    }
  }

  .content {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 40px;
    margin: 0 20px 24px 0;
    border-radius: 4px;
    background: var(--bg-primary-gray);

    @include media-xs-only {
      padding: 20px 26px;
    }

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: var(--font-forth-blue);
    }

    .filter-btn {
      box-sizing: border-box;
      height: 33px;
      padding: 0 40px;
      border: 1px solid var(--border-secondary-gray);
      border-radius: 17px;
      font-size: 15px;
      color: var(--font-primary-white);
      background: var(--bg-seventh-purple);
      cursor: pointer;

      @include media-sm-and-up {
        &:hover {
          opacity: 0.8;
        }
      }

      @include media-xs-only {
        padding: 0 20px;
      }
    }

    .item {
      margin: 25px 0;
    }

    .empty-tip {
      padding: 20px 0;
      font-weight: 500;
      font-size: 36px;
      line-height: 46px;
      text-align: center;
      color: var(--font-forth-blue);

      @include media-xs-only {
        font-size: 24px;
      }
    }
  }

  /deep/ .list-container {
    .list-desc {
      color: var(--font-seventh-purple);
    }

    .content {
      .list-desc + .due-time {
        margin-right: 24px;
        margin-bottom: 4px;
        margin-left: 12px;
        color: var(--font-forth-blue);
      }
    }

    .msg {
      margin-bottom: -6px;
    }
  }

  .more {
    .text {
      padding-top: 20px;
      text-decoration: underline;
      cursor: pointer;
    }

    .no-more {
      color: var(--font-fifth-gray);
    }
  }

  /deep/ .list-page {
    @include media-xs-only {
      padding-bottom: 0;
    }
  }

</style>
