<template>
  <div :class="['students list-page', { 'dark-theme': $isDarkTheme }]">
    <div class="page-title">Your Students</div>
    <div class="student-wrap">
      <el-collapse v-if="$get(studentsStore, 'data.length')">
        <el-collapse-item v-for="item in studentsStore.data" :key="item.id">
          <span class="collapse-title" slot="title">
            <div class="title-wrap">
              <div class="content flex item-center content-between wrap">
                <div class="left flex item-center wrap">
                  <img :src="$get(item, 'avatar.url') || require('@/assets/default-avatar.png')" alt="">
                  <router-link
                    :to="{ name: 'student.detail', params: { id: item.id } }"
                    class="name"
                  >
                    {{ item.name }}
                  </router-link>
                  <span @click.stop="$authStore.handleSwitchStudent(item.id)" class="switch-button" :class="{ 'switch-active': currentStudentId !== item.id }">
                    {{ currentStudentId === item.id ? 'Current account' : 'Switch to this account'}}
                  </span>
                </div>
                <div class="right">
                  <span v-if="item.overdue_tasks_count" class="task"> <span class="overdue">{{ item.overdue_tasks_count }}</span> {{ item.overdue_tasks_count | pluralize('overdue assignment', false, 'No') }}</span>
                  <span v-if="item.active_tasks_count" class="task"> {{ item.active_tasks_count  | pluralize('active assignment', true, 'No') }}</span>
                  <span v-if="item.tasks_count" class="task"> {{ item.tasks_count | pluralize('Total assignment', false, 'No') }}: {{ item.tasks_count }}</span>
                </div>
              </div>
            </div>
          </span>
          <div class="item-wrap">
            <div class="item-content" v-if="$get(item, 'school_students.length')">
              <div v-for="school in item.school_students" :key="school.id" class="item-line flex item-center content-between">
                <div class="line-left">
                  <span class="school-name">{{ school.school.name }}</span>
                </div>
                <div class="line-right">
                    <span v-if="school.overdue_tasks_count" class="task"> <span class="overdue">{{ school.overdue_tasks_count | pluralize('', true, 'No') }}</span> overdue assignments</span>
                    <span v-if="school.active_tasks_count" class="task"> {{ school.active_tasks_count | pluralize('active assignment', true, 'No') }}</span>
                    <span v-if="school.tasks_count" class="task"> {{ school.tasks_count | pluralize('all assignment', true, 'No') }}</span>
                </div>
              </div>
            </div>
            <div v-else class="line-empty flex content-center">No Result</div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div v-else class="empty">Sorry, there are currently no bound students</div>
      <common-prod-pagination :store="studentsStore"/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { studentsStore } from '@/stores';

  @Component
  export default class Students extends Vue {
    studentsStore = studentsStore

    get currentStudentId() {
      return this.$get(this.$authStore, 'user.current_role.id');
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.fetchData();
    }

    async fetchData() {
      const { page = 1 } = this.$route.query;
      Object.assign(this.studentsStore.params, { page, type: 'students' });
      await this.studentsStore.fetchData();
    }
  }
</script>

<style lang="scss" scoped>
  .dark-theme {
    --bg-primary-white: var(--bg-primary-gray);
  }

  .student-wrap {
    // collapse
    /deep/ .el-collapse {
      border: none;
      border-radius: 12px;

      .el-collapse-item__header {
        flex: 1 0 auto;
        order: -1;
        height: auto;
        border: 1px solid var(--border-secondary-gray);
        border-radius: 12px;
        background: var(--bg-primary-white);
        transition: all .5s;

        &.is-active {
          border: none;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          background: var(--bg-primary-gray);
        }
      }

      .el-collapse-item__wrap {
        border: none;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        background: var(--bg-primary-gray);
      }

      .el-collapse-item__content {
        padding-bottom: 0;

        @include media-xs-only {
          line-height: 20px;
        }
      }

      .el-icon-arrow-right {
        margin-left: 31px;
        color: var(--font-primary-black);

        @include media-xs-only {
          margin-left: 15px;
        }
      }
    }

    .title-wrap {
      padding: 14px 40px 14px 6px;

      @include media-xs-only {
        padding: 7px 20px 7px 3px;
      }

      .left {
        color: var(--font-primary-black);

        img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          object-fit: cover;
        }

        .name {
          width: 130px;
          margin-left: 24px;
          overflow: hidden;
          font-size: 18px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .switch-button {
          display: inline-block;
          width: 191px;
          padding: 5px;
          margin-left: 16px;
          border: 1px solid var(--border-secondary-gray);
          border-radius: 8px;
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          color: var(--font-secondary-gray);
          cursor: default;

          @include media-xs-only {
            margin: 0 5px 10px 0;
            line-height: 20px;
          }

          &.switch-active {
            border: none;
            color: var(--font-primary-white);
            background: var(--bg-primary-purple);
            cursor: pointer;
          }
        }
      }

      .right {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: var(--font-forth-blue);

        @include media-xs-only {
          font-size: 16px;
        }

        .task + .task {
          margin-left: 12px;
        }
      }
    }

    .item-wrap {
      padding: 0 40px 0 109px;

      @include media-xs-only {
        padding: 0 20px;
      }

      .item-content {
        padding: 16px 0;
        border-top: 1px solid var(--border-secondary-gray);
      }

      .line-empty {
        padding: 40px 0;
        border-top: 1px solid var(--border-secondary-gray);
        font-size: 18px;
      }

      .item-line {
        @include media-xs-only {
          align-items: flex-start;
        }
      }

      .item-line + .item-line {
        margin-top: 32px;

        @include media-xs-only {
          margin-top: 25px;
        }
      }

      .line-left {
        @include media-xs-only {
          width: 200px;
        }

        .school-name {
          font-size: 18px;
          color: var(--font-primary-black);
        }
      }

      .line-right {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: var(--font-forth-blue);

        @include media-xs-only {
          margin-left: 12px;
          font-weight: 500;
          font-size: 16px;
        }

        .task {
          @include media-xs-only {
            display: block;
            width: 120px;
          }
        }

        .task + .task {
          margin-left: 24px;

          @include media-xs-only {
            margin: 12px 0 0;
          }
        }
      }
    }
  }

  .overdue {
    color: var(--font-primary-red);
  }

  .empty {
    margin: 80px 0;
    font-size: 36px;
    color: var(--font-secondary-black);
  }

  .collapse-title {
    flex: 1 0 90%;
    order: 1;
  }

  .el-collapse-item {
    margin-bottom: 16px;
  }
</style>
