<template>
  <div class="file-list-wrapper">
    <div class="flex item-center item" v-for="item in files" :key="item.id">
      <div class="item-line">
        <div class="top-content flex">
          <FileItem :file="item"/>
          <div v-if="showTag" class="tag-wrap">
            <common-prod-tag v-for="tag in $get(item, 'document_taggings', [])" :key="tag.id"
              :tagText="tag.taggable_name"
              :toggle="false"
            />
          </div>
        </div>
        <div class="bottom-content flex item-center">
          <div class="time">
            {{`Uploaded ${$options.filters.formatTime(item.updated_at)} by ${$get(item, 'uploader.name')}`}}
          </div>
          <el-popconfirm
            v-if="(isNormal && $authStore.isAdvisor) || item.uploader_id === $get($authStore, 'user.id')"
            confirm-button-text='Delete'
            cancel-button-text='Close'
            icon="el-icon-info"
            icon-color="red"
            title="Confirm delete？"
            @confirm="$emit('delete-item', item)"
          >
            <div slot="reference" class="delete-btn">Delete</div>
          </el-popconfirm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import FileItem from './file-item.vue';

  @Component({
    components: {
      FileItem
    }
  })
  export default class FileLists extends Vue {
    @Prop({ type: Array, default: () => [] }) files
    @Prop({ type: Boolean, default: true }) isNormal
    @Prop({ type: Boolean, default: false }) showTag
  }
</script>

<style lang="scss" scoped>
  .file-list-wrapper {
    .item {
      position: relative;
      margin: 25px 0;

      &:hover {
        .delete-btn {
          display: block;
        }
      }

      .item-line {
        font-size: 24px;

        .top-content {
          @include media-xs-only {
            display: block;
          }

          /deep/ .tag {
            @include media-xs-only {
              padding: 6px 15px;
              margin-top: 6px;
            }
          }
        }

        .bottom-content {
          margin-top: 6px;

          .time {
            font-size: 16px;
            color: var(--font-primary-blue);
          }
        }
      }

      .delete-btn {
        display: none;
        margin-left: 20px;
        font-size: 16px;
        color: var(--font-primary-red);
        cursor: pointer;
      }
    }

    .tag-wrap {
      margin-left: 5px;

      /deep/ .tag + .tag {
        margin-left: 10px;
      }
    }
  }
</style>
