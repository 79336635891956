<template>
  <div class="form-wrapper">
    <div class="rich-text-wrapper">
      <common-prod-input v-model="content" type="richtext" :inputTitle="`${type} ${type === 'Add' ? 'a' : 'the'} comment?`"/>
    </div>
    <!-- 客户要求不要这个紫色按钮，先注释掉 -->
    <!-- <div class="file-input-wrapper">
      <common-prod-upload-input v-model="files"/>
    </div> -->
    <common-prod-button
      class="submit-comment-btn"
      :disabled="disabled"
      @click.native="handleSubmitComment"
    ><span class="uppercase">{{ type }} A COMMENT</span></common-prod-button>
  </div>
</template>

<script>
  import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class CommentForm extends Vue {
    @Prop({ type: String, default: 'Add' }) type
    @Prop({ type: [Object, String], default: '' }) value
    @Prop({ type: Function, default: () => {} }) submit

    content = ''
    files = []

    get form() {
      const content = this.content;
      const data = {};
      this.files.map(item => {
        if (!data.documents_attributes) {
          data.documents_attributes = [];
        }
        data.documents_attributes.push(_.pick(item, ['id', '_destroy', 'attachment']));
      });
      content && (data.content = content);
      return data;
    }

    get disabled() {
      return Object.keys(this.form).length === 0;
    }

    @Watch('value', { immediate: true })
    valueChange() {
      if (!this.value || typeof this.value === 'string') {
        this.content = this.value;
        this.files = [];
      } else {
        this.content = _.get(this.value, 'content', '');
        this.files = _.get(this.value, 'documents_attributes', []);
      }
    }

    async handleSubmitComment() {
      if (!this.disabled) {
        _.isFunction(this.submit) && (await this.submit({ ...this.form }));
        this.content = '';
        this.files = [];
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form-wrapper {
    .rich-text-wrapper {
      max-width: 900px;
      margin-bottom: 24px;
    }

    .file-input-wrapper {
      width: 653px;
      max-width: 50vw;

      @include media-xs-only {
        max-width: 100%;
      }
    }

    .submit-comment-btn {
      margin-top: 25px;
    }
  }
</style>
