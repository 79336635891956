<template>
  <div class="nickname">
    <common-started-layout>
      <div class="nickname-wrap flex column item-center">
        <div class="title">Would you prefer to be called by a nickname?</div>
        <common-prod-input
          inputTitle="Nickname (Optional)"
          placeholder=""
          v-model.trim="nickname"
          focus
        />
        <common-prod-button @click.native="handleClick" size="large">
          START USING ATOMICMIND
        </common-prod-button>
      </div>
    </common-started-layout>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class SettingNickname extends Vue {
    nickname = ''

    async created() {
      await this.$authStore.tryFetchData();
      this.nickname = this.$get(this.$authStore, 'user.screen_name') || '';
    }

    handleClick() {
      if (this.nickname !== this.$get(this.$authStore, 'user.screen_name')) {
        this.$authStore.updatePersonalInfo({ screen_name: this.nickname });
        this.$message({ message: 'Update nickname successfully', type: 'success' });
      }
      this.$router.push({ name: 'home' });
    }
  }
</script>

<style lang="scss" scoped>
  .nickname-wrap {
    padding: 92px 0;
    text-align: center;

    @include media-xs-only {
      padding: 48px 32px;
    }

    .title {
      font-size: 36px;
      color: var(--font-secondary-black);

      @include media-xs-only {
        font-size: 32px;
      }
    }

    .input {
      margin: 55px 0 64px;
    }

    /deep/ .ato-button {
      @include media-xs-only {
        height: auto;
        font-size: 20px;
      }
    }
  }
</style>
