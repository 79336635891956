<template>
  <div
    v-if="tagText"
    class="tag font-regular"
    :class="[active ? 'default' : 'is-disabled', {'is-toggle': toggle} ]"
    @click="$emit('change', !active)"
  >
    {{ tagText }}
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class Tag extends Vue {
    @Prop({ type: [String, Number], required: true }) tagText
    @Prop({ type: Boolean, default: true }) active
    @Prop({ type: Boolean, default: true }) toggle  // 是否为可切换tag
  }
</script>

<style lang="scss" scoped>
  .tag {
    display: inline-block;
    max-width: 150px;
    padding: 6px 15px;
    border-radius: 20px;
    overflow: hidden;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--font-primary-white);

    @include media-xs-only {
      padding: 8px 15px;
      font-size: 14px;
    }

    &.default {
      background: var(--radial-gradient-blue);
    }

    &.is-disabled {
      border-radius: 20px;
      background: var(--bg-secondary-gray);
    }

    &.is-toggle {
      cursor: pointer;
    }
  }
</style>
