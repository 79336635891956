import Vue from 'vue';
import './styles/global.scss';
import './styles/element-ui.scss';
import App from './app';
import './hotjar';
import './plugin/dayjs';
import { request, sleep } from './utils';
import './components';
import './filters';
import './mixins';
import router from './router';
import { Collection, authStore, settingsStore } from './stores';
import _ from 'lodash';
import { ACCESS_TOKEN_KEY } from '@/constants';

Vue.config.productionTip = false;
Vue.prototype.$sleep = sleep;
Vue.prototype.$request = request;
Vue.prototype.$get = _.get;
Vue.prototype.$Collection = Collection;
Vue.prototype.$authStore = authStore;
Vue.prototype.$settingsStore = settingsStore;

window.addEventListener('message', event => {
  const data = event.data || {};
  // FJP 退出登录时，CAP 也要退出登录，不然去到登录页面，又会跳转到 CAP
  if (event.origin === process.env.VUE_APP_FJP_HOST && data.type === 'logout') {
    localStorage.setItem(ACCESS_TOKEN_KEY, '');
  }
}, false);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
