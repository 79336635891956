const { VUE_APP_ENV } = process.env;

export const ACCESS_TOKEN_KEY = `${VUE_APP_ENV}_access_token`;
export const CURRENT_STUDENT_ID = `${VUE_APP_ENV}_current_student_id`;
export const IS_DARK_THEME = `${VUE_APP_ENV}_is_dark_theme`;
export const VALIDATE_PASSWORD = /^(?=.*[A-Za-z])(?=.*[0-9])(.{8,})$/;
export const CHECK_PASSWORD_TIP = 'The password length must be greater than 8, and contain both numbers and letters.';
export const SAME_PASSWORD_TIP = 'Inconsistent input of new password';
export const REDIRECT_URL_SESSION_KEY = `${VUE_APP_ENV}_redirect_session_url`;

export const LABEL_COLORS = {
  tomato: 'rgb(213, 0, 0)',
  flamingo: 'rgb(230, 124, 115)',
  tangerine: 'rgb(244, 81, 30)',
  banana: 'rgb(246, 191, 38)',
  sage: 'rgb(51, 182, 121)',
  basil: 'rgb(11, 128, 67)',
  peacock: 'rgb(3, 155, 229)',
  blueberry: 'rgb(63, 81, 181)',
  lavender: 'rgb(121, 134, 203)',
  graphite: 'rgb(97, 97, 97)',
};
