import { SimpleStore } from './helper/simple-store';
import { request } from '@/utils';

class TimeZonesStore extends SimpleStore {
  data = []

  async fetchData() {
    const { data } = await request.get('countries/time_zones');
    this.data = data;
  }
}

export const timeZonesStore = new TimeZonesStore();
