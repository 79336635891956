<template>
  <div>
    <span @click="showPopup = true" class="text">{{ $get(file, 'attachment.filename') }}</span>
    <common-popup v-model="showPopup">
      <div class="popup-wrapper">
        <div class="popup-content flex item-center content-center">
          <img v-if="contentType === 'image'" :src="url"/>
          <video v-else-if="contentType === 'video'" :src="url" controls/>
          <audio v-else-if="contentType === 'audio'" :src="url" controls/>
          <span v-else class="text">{{ $get(file, 'attachment.filename') }}</span>
        </div>
        <div class="popup-footer flex content-center item-center">
          <a class="btn" :href="url" target="_blank" v-if="contentType === 'pdf'" >
            <common-prod-button class="uppercase">Preview</common-prod-button>
          </a>
          <a class="btn" :href="`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`" target="_blank" v-if="contentType === 'officedocument'" >
            <common-prod-button class="uppercase">Preview</common-prod-button>
          </a>
          <!-- 图片无法使用a标签下载，改为通过 js 下载文件 -->
          <div class="btn" @click="download(url)">
            <common-prod-button class="uppercase">Download</common-prod-button>
          </div>
        </div>
      </div>
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import download from 'downloadjs';

  @Component
  export default class FileItem extends Vue {
    @Prop({ type: Object, defaule: () => ({}) }) file

    showPopup = false

    download = download

    get url() {
      return this.$get(this.file, 'attachment.url');
    }

    get contentType() {
      const contentType = this.$get(this.file, 'attachment.content_type');
      if (/image/gi.test(contentType)) {
        return 'image';
      } else if (/video/gi.test(contentType)) {
        return 'video';
      } else if (/audio/gi.test(contentType)) {
        return 'audio';
      } else if (/officedocument/gi.test(contentType)) {
        return 'officedocument';
      } else if (/pdf/gi.test(contentType)) {
        return 'pdf';
      }
      return 'other';
    }
  }
</script>

<style lang="scss" scoped>
  .text {
    margin-right: 12px;
    text-decoration: underline;
    word-break: break-all;
    color: var(--font-seventh-purple);
    cursor: pointer;

    @include media-xs-only {
      margin-right: 0;
    }
  }

  .popup-content {
    width: 500px;
    max-width: 100%;

    > video, audio, img {
      width: 100%;
      background: var(--bg-primary-gray);
    }
  }

  .popup-footer {
    margin-top: 40px;

    .btn {
      margin: 0 10px;
    }
  }
</style>
