import { Collection } from './helper/collection';
import { request } from '@/utils';
import dayjs from 'dayjs';

export class TasksStore extends Collection {
  fetch(params) {
    return request.get('tasks', { params });
  }

  get thisWeekTasks() {
    const toDay = dayjs();
    const thisWeek = toDay.add(1, 'week');
    return this.data.filter(item => dayjs(item.due_at).isBefore(thisWeek) && !dayjs(item.due_at).isBefore(toDay));
  }

  get overdueTasks() {
    return this.data.filter(item => dayjs(item.due_at).isBefore(dayjs()));
  }

  get laterTasks() {
    const thisWeek = dayjs().add(1, 'week');
    return this.data.filter(item => !dayjs(item.due_at).isBefore(thisWeek));
  }

  async add(body) {
    const { data } = await request.post('tasks', body);
    this.data.unshift(data);
  }
}

export const tasksStore = TasksStore.create();
