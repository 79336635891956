<template>
  <transition name="el-fade-in">
    <div v-show="show" class="dropdown-card">
      <div class="mask" @click="$emit('input', false)"></div>
      <div class="wrapper">
        <div class="switch-platform">
          <div class="platform" @click="handleLoginFJP">Grades 9-11 Platform</div>
          <div class="platform active">Senior Platform</div>
        </div>

        <div class="main-container">
          <div
            v-if="$get($authStore, 'user.role') === 'advisor' && $get(accountList, 'length')"
            class="account-list"
          >
            <div class="list-title">Switch student account</div>
            <div v-for="(item, index) in accountList.slice(0, 3)" :key="index" class="account-item flex item-center">
              <img :src="$get(item, 'avatar.url') || require('@/assets/default-avatar.png')" alt="">
              <div class="item-info flex column">
                <span :class="{'name': $get($authStore, 'user.current_role.id') !== item.id}" @click="$authStore.handleSwitchStudent(item.id)">{{ item.name }}</span>
                <span v-show="$get($authStore, 'user.current_role.id') === item.id" class="current-text">Current account</span>
              </div>
            </div>
          </div>
          <div class="router-list">
            <div v-for="(item, index) in routerList" :key="index" class="router-item flex column item-end">
              <router-link :to="{ name: item.name }">
                <span>{{ item.text }}</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="sign-out">
          <span @click="handleLogout">Sign out</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { Vue, Component, Model, Watch } from 'vue-property-decorator';
  import urlJoin from 'url-join';

  @Component
  export default class DropdownCard extends Vue {
    @Model('input', Boolean) show

    get routerList() {
      return [
        !this.isStudent && {
          name: 'students',
          text: 'Students'
        },
        {
          name: 'profile',
          text: 'Your profile'
        },
        {
          name: 'preferences',
          text: 'Preferences'
        },
        this.isStudent && {
          name: 'billing',
          text: 'Billing'
        }
      ].filter(Boolean);
    }

    get accountList() {
      return this.$get(this.$authStore, 'user.students');
    }

    get isStudent() {
      return this.$get(this.$authStore, 'user.role') === this.$get(this.$authStore, 'user.current_role.role');
    }

    @Watch('$route', { immediate: true })
    routeChange() {
      this.$emit('input', false);
    }

    // 直接登陆 CAP 高年级平台
    @Vue.autoLoadingProgress
    async handleLoginFJP() {
      const { data } = await this.$request.get('simple_sso/tokens/token');
      const href = urlJoin(process.env.VUE_APP_FJP_HOST, 'login/simple_sso', `?token=${data.token}`);
      location.href = href;
    }

    async handleLogout() {
      await this.$confirm('Confirm logout？');
      await this.$autoLoading(this.$authStore.logout());
      this.$router.replace({ name: 'login', query: { logout: Date.now() } });
    }
  }
</script>

<style lang="scss" scoped>
  .dropdown-card {
    position: absolute;
    z-index: 999;
    right: 26px;
    box-sizing: border-box;
    width: 277px;
    margin-top: 10px;
    font-size: 18px;

    .wrapper {
      position: relative;
      z-index: 999;
      width: 278px;
      background-color: var(--bg-primary-gray);
      box-shadow: 0px 3px 18px var(--secondary-gray);

      .switch-platform {
      padding: 18px 16px;

      .platform {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 50px;
        padding: 0 23px;
        font-size: 18px;
        background: transparent;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &.active,
        &:hover {
          border-radius: 7px;
          color: var(--font-primary-white);
          background: var(--bg-primary-purple);
          cursor: pointer;
        }
      }
    }

      .main-container {
        padding: 20px 23px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.11);

        .account-list {
          color: var(--font-secondary-black);

          .list-title {
            margin-bottom: 16px;
          }

          .account-item {
            margin-bottom: 16px;

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              object-fit: cover;
            }

            .item-info {
              margin-left: 24px;

              .name {
                cursor: pointer;
              }

              .current-text {
                margin-top: 5px;
                color: var(--font-primary-purple);
              }
            }
          }
        }

        .router-list {
          color: var(--font-primary-red);

          .router-item {
            margin-bottom: 16px;
          }
        }
      }

      .sign-out {
        padding: 17px 23px;
        text-align: right;
        color: var(--font-primary-red);

        span {
          cursor: pointer;
        }
      }
    }
  }

  .mask {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
