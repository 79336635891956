<template>
  <div class="reset-password">
    <common-started-layout>
      <div class="wrapper">
        <div class="title">Welcome to the board! Please set your new password.</div>
        <common-prod-password-group center :profile="false"/>
      </div>
    </common-started-layout>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class ResetPassword extends Vue {}
</script>

<style lang="scss" scoped>
  .wrapper {
    padding-top: 100px;

    @include media-xs-only {
      padding: 32px 20px 20px;
    }
  }

  .title {
    font-size: 48px;
    text-align: center;
    color: var(--font-secondary-gray);

    @include media-xs-only {
      font-size: 44px;
    }
  }

  /deep/ .footer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
</style>
