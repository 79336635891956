<template>
  <div class="add-task">
    <div class="input-group">
      <el-row :gutter="32">
        <el-col :sm="24" :xs="24">
          <common-prod-input
            v-model.trim="dataForm.name"
            inputTitle="Assignment Name"
            @input="changeName"
          />
          <div v-show="errorLengthTip" class="input-error-msg flex content-between">
            <div>{{ errorLengthTip }}</div>
            <div>{{ currentLengthTip }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="32">
        <el-col :sm="12" :xs="24">
          <common-prod-select
            v-model="dataForm.task_category_id"
            placeholder="None selected"
            :options="categoriesStore.data"
            inputTitle="Add a category"
          />
        </el-col>
        <el-col :sm="12" :xs="24">
          <common-prod-select
            v-model="dataForm.school_student_id"
            placeholder="None selected"
            :options="schoolStudentsStore.data"
            name="school.name"
            inputTitle="Add a school (Optional)"
          >
            <template v-slot:suffix>
              <i @click="handleDeleteSchool" class="el-icon-close"></i>
            </template>
          </common-prod-select>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" :gutter="32" >
        <el-col :sm="12" :xs="24">
          <common-prod-input type="onlySelect" inputTitle="Due Date">
            <template v-slot:selectInput>
              <DatePicker v-model="dataForm.due_at" label="Due Date"/>
            </template>
          </common-prod-input>
        </el-col>
        <el-col :sm="12" :xs="24">
          <div class="date-option flex">
            <div class="date" @click="handleTime(0, 'day')">Today</div>
            <div class="date" @click="handleTime(1, 'day')">Tomorrow</div>
            <div class="date" @click="handleTime(1, 'week')">One Week</div>
            <div class="date" @click="handleTime(1, 'month')">One Month</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="32">
        <el-col :sm="12" :xs="24">
          <common-prod-select
            v-model="dataForm.time_zone"
            :options="timeZonesStore.data"
            valueKey="value"
            placeholder="None selected"
            inputTitle="Time zone"
          />
        </el-col>
      </el-row>
      <el-row :gutter="32">
        <el-col :xs="24">
          <common-prod-input v-model="dataForm.desc" type="richtext" inputTitle="What needs to be done? (Optional)"/>
        </el-col>
      </el-row>
      <el-row :gutter="32" v-if="!isEdit">
        <el-col :xs="24">
          <common-prod-upload-input v-model="dataForm.documents_attributes"/>
        </el-col>
      </el-row>
    </div>
    <common-prod-button :disabled="disabled" @click.native="handleSubmit">{{ isEdit ? 'EDIT': 'ADD' }} ASSIGNMENTS</common-prod-button>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import _ from 'lodash';
  import dayjs from 'dayjs';
  import { categoriesStore, schoolStudentsStore, timeZonesStore } from '@/stores';
  import DatePicker from './date-picker.vue';

  const defaultFormData = {
    task_category_id: '',
    school_student_id: '',
    name: '',
    due_at: dayjs().format('YYYY-MM-DD HH:mm'),
    desc: '',
    time_zone: dayjs.tz.guess(),
    documents_attributes: []
  };

  @Component({
    components: {
      DatePicker
    }
  })
  export default class TaskForm extends Vue {
    @Prop({ type: Boolean, default: false }) isEdit
    @Prop({ type: Object, default: () => ({}) }) task
    @Prop(Function) submit

    originalForm = {}
    categoriesStore = categoriesStore
    timeZonesStore = timeZonesStore
    schoolStudentsStore = schoolStudentsStore
    dataForm = _.cloneDeep(defaultFormData)
    errorLengthTip = ''
    currentLengthTip = ''

    get disabled() {
      return !_.every(_.omit(this.dataForm, ['school_student_id', 'desc'])) || !!this.errorLengthTip || !this.isUpdate;
    }

    get isUpdate() {
      return !_.isEqual(this.dataForm, this.originalForm);
    }

    @Watch('task', { immediate: true })
    async getDetailData(val) {
      if (!_.isEmpty(val)) {
        this.dataForm = _.pick(this.task, Object.keys(defaultFormData));
        this.originalForm = _.pick(this.task, Object.keys(defaultFormData));
      }
    }

    async created() {
      await Promise.all([
        timeZonesStore.tryFetchData(),
        this.categoriesStore.fetchData(),
        this.schoolStudentsStore.fetchData()
      ]);
    }

    changeName() {
      if (this.dataForm.name.length > 100) {
        this.errorLengthTip = 'The assignment name cannot exceed 100 fields';
        this.currentLengthTip = `The current fields are ${this.dataForm.name.length}`;
        return;
      }
      this.errorLengthTip = '';
    }

    handleDeleteSchool() {
      this.dataForm.school_student_id = null;
    }

    async handleSubmit() {
      const body = { ...this.dataForm };
      if (_.isFunction(this.submit)) {
        await this.submit(body);
      }
      if (!this.isEdit) {
        this.file = {};
        this.dataForm = _.cloneDeep(defaultFormData);
      }
      this.$emit('close');
    }

    handleTime(...agrs) {
      this.dataForm.due_at = dayjs().add(...agrs).format('YYYY-MM-DD HH:mm');
    }
  }
</script>

<style lang="scss" scoped>
  .date-option {
    margin-top: 26px;
    font-size: 18px;

    @include media-xs-only {
      justify-content: center;
      align-items: center;
      margin-top: 0;
      font-size: 16px;
    }

    .date {
      cursor: pointer;

      &:active {
        color: var(--font-primary-purple);
      }
    }

    .date + .date {
      &::before {
        padding: 5px;
        content: '|';
      }
    }
  }

  /deep/ .input {
    .suffix-icon {
      &.icon {
        width: 24px;
        padding-right: 8px;
      }
    }
  }

  /deep/ .el-col {
    margin-bottom: 24px;
  }

  /deep/ .el-row--flex {
    @include media-xs-only {
      flex-direction: column;
    }
  }

  /deep/ .el-icon-close {
    font-size: 20px;
    cursor: pointer;
  }
</style>
