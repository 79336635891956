<template>
  <transition name="el-fade-in">
    <div class="mask-wrap" v-if="showGuide && !$authStore.isAdvisor">
      <div v-for="(i, index) in tips" :key="index">
        <transition name="el-fade-in">
          <div :class="`tips tip-${i}`" v-if="index === step">
            <img class="icon-arrow hidden-xs-only" mode="widthFix" src="@/assets/icon-left.png"/>
            <img class="icon-arrow hidden-sm-and-up" mode="widthFix" src="@/assets/icon-down.png"/>
            <common-prod-guide-card class="guide"
              :title="stepContent.title"
              :currentStep="step"
              :length="tips.length"
              @click-btn="handleClick"
            >
              {{ stepContent.tip }}
            </common-prod-guide-card>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
  import { Component, Vue, Model, Watch } from 'vue-property-decorator';

  @Component
  export default class GuideTips extends Vue {
    @Model('input', Boolean) showGuide;
    step = 0
    tips = ['one', 'two', 'three', 'four', 'five']

    get stepContent() {
      return [
        {
          title: 'FIND YOUR WAY AROUND',
          tip: 'On your Dashboard, easily find your most important upcoming assignments and see recent activity.'
        },
        {
          title: 'MANAGE YOUR ASSIGNMENTS AND FILES',
          tip: 'In the "Assignments" and "Files" tabs, see and discuss assignments you\'ve been assigned, and upload and access files related to them, like essay drafts.'
        },
        {
          title: 'FOCUS ON YOUR TARGETS',
          tip: 'The "Schools" tabs will allow you to sort assignments and files by your target schools and discuss your strategy with the AtomicMind team.'
        },
        {
          title: 'CONTACT YOUR ADVISOR',
          tip: `${this.$get(this.$authStore, 'user.head_advisor.name')} is your Head Advisor. If you have questions or need help at any time you can send a message or request a call.`
        },
        {
          title: 'CATEGORIES',
          tip: 'When you log in, you\'ll be able to see the most important assignment in each category right away.'
        }
      ][this.step];
    }

    @Watch('showGuide', { immediate: true })
    valueChange(val) {
      document.body.classList[val ? 'add' : 'remove']('disabled-scrolling');
    }

    async handleClick(val) {
      switch (val) {
        case 'skip':
          await this.$authStore.updateIsShow('show_tool_guide', false);
          this.$emit('input', false);
          break;
        case 'back':
          this.step = --this.step;
          break;
        case 'next':
          this.step = ++this.step;
          break;
        case 'toWork': {
          await this.$authStore.updateIsShow('show_tool_guide', false);
          this.$emit('input', false);
          // “Let’s get to work” button will disable tutorial for subsequent log ins -
          this.$emit('guide-btn', val);
          break;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mask-wrap {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);

    @include media-xs-only {
      z-index: 999;
      background: rgba(0, 0, 0, 0.75);
    }
  }

  .icon-arrow {
    width: 90px;
    height: 66px;

    @include media-xs-only {
      position: absolute;
      bottom: -88px;
      width: 22px;
      height: 64px;
    }
  }

  .guide-card {
    margin-top: -25px;
    margin-left: 64px;

    @include media-xs-only {
      margin-left: 0;
    }
  }

  .tips {
    position: absolute;
    z-index: 1200;

    @include media-xs-only {
      bottom: 176px;
      left: 0;
    }
  }

  .tip-one {
    @include media-sm-and-up {
      top: 49px;
      left: 170px;
    }

    @include media-xs-only {
      .icon-arrow {
        left: 40px;
      }
    }
  }

  .tip-two {
    @include media-sm-and-up {
      top: 130px;
      left: 133px;
    }

    @include media-xs-only {
      .icon-arrow {
        left: 28%;
      }
    }
  }

  .tip-three {
    @include media-sm-and-up {
      top: 262px;
      left: 149px;
    }

    @include media-xs-only {
      .icon-arrow {
        bottom: -88px;
        right: 28%;
      }
    }
  }

  .tip-four {
    @include media-sm-and-up {
      bottom: 60px;
      right: 110px;

      .icon-arrow {
        transform: rotateY(180deg);
      }

      .guide-card {
        margin-top: -176px;
        margin-left: -680px;
      }
    }

    @include media-xs-only {
      bottom: 260px;

      .icon-arrow {
        right: 8%;
      }
    }
  }

  .tip-five {
    @include media-sm-and-up {
      position: static;

      .icon-arrow {
        position: absolute;
        top: 440px;
        left: 56%;
        transform: rotate(60deg);
      }

      .guide {
        position: absolute;
        top: 540px;
        left: 50%;
      }
    }

    @include media-xs-only {
      top: 120px;

      .icon-arrow {
        top: 200px;
        right: 50%;
      }
    }
  }
</style>
