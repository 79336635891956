<template>
  <div class="date-wrap flex item-center content-between">
    <img v-if="$isDarkTheme" class="icon" src="@/assets/dark-date.png" mode="widthFix" alt="">
    <img v-else class="icon" src="@/assets/date.png" mode="widthFix" alt="">
    <el-date-picker
      v-model="inputLabel"
      :type="type"
      :placeholder="placeholder"
      :clearable="false"
      format="MMM d, yyyy HH:mm A"
      value-format="yyyy-MM-dd HH:mm"
      ref="datePicker"
      @change="$emit('input', String($event))"
    ></el-date-picker>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

  @Component
  export default class datePicker extends Vue {
    @Prop({ type: String, default: '' }) label
    @Prop({ type: String, default: 'datetime' }) type
    @Prop({ type: String, default: 'No date' }) placeholder
    @Prop({ type: String }) value

    inputLabel = null

    @Watch('value', { immediate: true })
    valueChange(val) {
      this.inputLabel = new Date(val);
    }

    mounted() {
      this.$nextTick(() => {
        if (!this.$refs.datePicker.picker) {
          this.$refs.datePicker.mountPicker();
        }
        // format 为 “MMM d, yyyy HH:mm A”，time Format 会显示成 “, HH:mm A”
        this.$refs.datePicker.picker.format = 'MMM d,yyyy HH:mm A';
      });
    }
  }
</script>

<style lang="scss" scoped>

  .date-wrap {
    box-sizing: border-box;
    height: 64px;

    .icon {
      width: 24px;
      height: 24px;
      padding-left: 20px;
    }
  }

  /deep/ .el-date-editor {
    width: 100%;
    padding: 0 20px;

    input {
      padding: 0;
      border: none;
      outline: none;
      font-size: 24px;
      color: var(--font-secondary-black);
      background-color: transparent;

      &::placeholder {
        color: var(--font-secondary-gray);
      }
    }

    .el-input__prefix {
      display: none;
    }
  }
</style>
