<template>
  <div class="started-layout flex column">
    <common-started-header v-if="showHeader" />
    <div class="content flex-1">
      <slot />
    </div>
    <common-started-footer />
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class StartLayout extends Vue {
    @Prop({ type: Boolean, default: true }) showHeader
  }
</script>

<style lang="scss" scoped>
  .started-layout {
    height: 100vh;

    .content {
      overflow: auto;
    }
  }
</style>
