import { Vue } from 'vue-property-decorator';
import {
  Loading,
  Message,
  MessageBox,
  Notification,
  Progress, DatePicker,
  TimePicker,
  Dialog,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Upload,
  Button,
  Popconfirm,
  Pagination,
  Autocomplete,
  Row, Col,
  Radio,
  RadioGroup,
  Collapse,
  CollapseItem,
  Tooltip
} from 'element-ui';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

// 设置语言
locale.use(lang);

Vue.use(Progress)
  .use(DatePicker)
  .use(TimePicker)
  .use(Dialog)
  .use(Tabs)
  .use(TabPane)
  .use(Form)
  .use(FormItem)
  .use(Input)
  .use(Select)
  .use(Option)
  .use(Upload)
  .use(Button)
  .use(Popconfirm)
  .use(Pagination)
  .use(Autocomplete)
  .use(Row)
  .use(Col)
  .use(Radio)
  .use(RadioGroup)
  .use(Collapse)
  .use(CollapseItem)
  .use(Tooltip);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$MessageBox = MessageBox;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
