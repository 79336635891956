<template>
  <div class="login">
    <common-started-layout :showHeader="false">
      <div class="container flex content-around">
        <!-- left -->
        <div class="left flex column">
          <div class="logo">
            <img src="@/assets/atomicmind-grow-logo.svg" alt="" />
            <img src="@/assets/atomicmind-apply-logo.svg" alt="" />
          </div>
          <div>
            <div class="title">{{ typeContent.title }}</div>
            <div class="sub-title">{{ typeContent.subTitle }}</div>
          </div>
          <!-- 登录 -->
          <div class="input-group" v-if="type === 'login'">
            <common-prod-input
              v-model.trim="loginForm.email"
              inputTitle="Email"
              @keyup.enter.native="handleClick"
            />
            <common-prod-input
              type="password"
              v-model.trim="loginForm.password"
              inputTitle="Password"
              @keyup.enter.native="handleClick"
            />
          </div>
          <!-- 忘记密码 -->
          <div class="input-group" v-if="type === 'fogot_password'">
            <common-prod-input
              v-model.trim="emailAddress"
              inputTitle="Email Address"
              @keyup.enter.native="handleClick"
              @input="handleChangeEmail"
            />
            <div v-show="sendTip" class="input-error-msg success-tip">{{ sendTip }}</div>
          </div>
          <!-- 重设密码 -->
          <div class="input-group" v-if="type === 'reset_password'">
            <common-prod-input
              type="password"
              v-model.trim="password"
              inputTitle="New Password"
              @keyup.enter.native="handleClick"
              @input="invalidFormat = ''"
            />
            <div v-show="invalidFormat" class="input-error-msg">{{ invalidFormat }}</div>
            <common-prod-input
              type="password"
              v-model.trim="passwordConfirm"
              inputTitle="Confirm Password"
              @keyup.enter.native="handleClick"
            />
          </div>
          <!-- 注册 -->
          <div class="input-group" v-if="type === 'signup'">
            <common-prod-input
              v-model.trim="signupForm.email"
              inputTitle="Email"
              @keyup.enter.native="handleSendCode"
              @input="handleChangeEmail"
            />
            <div v-show="sendTip" class="input-error-msg success-tip">{{ sendTip }}</div>
            <common-prod-button class="send-btn" :disabled="!this.signupForm.email || disabledCodeBtn" @click.native="handleSendCode">
              <span v-show="disabledCodeBtn">
                <common-countdown
                  ref="countdown"
                  name="sign_up"
                  :duration="60000"
                  :start-time="resetPasswordTime"
                  @start="disabledCodeBtn = true"
                  @end="disabledCodeBtn = false"
                ></common-countdown> S&nbsp;&nbsp;LEFT
              </span>
              <span v-show="!disabledCodeBtn">Send Code</span>
            </common-prod-button>
            <common-prod-input
              v-model.trim="signupForm.verification_code"
              inputTitle="Verification Code"
              @keyup.enter.native="handleClick"
            />
            <common-prod-input
              type="password"
              v-model.trim="signupForm.password"
              inputTitle="Password"
              @keyup.enter.native="handleClick"
              @input="invalidFormat = ''"
            />
            <div v-show="invalidFormat" class="input-error-msg">{{ invalidFormat }}</div>
            <common-prod-input
              type="password"
              v-model.trim="signupForm.password_confirmation"
              inputTitle="Confirm Password"
              @keyup.enter.native="handleClick"
            />
          </div>
          <common-prod-button size="large" :disabled="typeContent.disabled" @click.native="handleClick">{{ typeContent.btnText }}</common-prod-button>
          <div class="guide" v-if="type !== 'reset_password'">
            <router-link v-if="type === 'login'" :to="{ query: { ...$route.query, type:'fogot_password' } }" replace>Forgot your email or password?</router-link>
            <router-link v-if="type === 'fogot_password' || type === 'signup'" :to="{ query: { ...$route.query, type:'login' } }" replace>Sign in to Atomicmind</router-link>
            <router-link v-if="type !== 'signup'" :to="{ query: { ...$route.query, type:'signup' } }" replace>New to AtomicMind?</router-link>
          </div>
        </div>
        <!-- right -->
        <div class="right hidden-sm-and-down">
          <div class="motto">“What I have achieved by industry and practice, anyone else with tolerable natural gift and ability can also achieve.”</div>
          <div class="name">J.S. Bach</div>
        </div>
      </div>
    </common-started-layout>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import urlJoin from 'url-join';
  import _ from 'lodash';
  import Schema from 'async-validator';
  import { VALIDATE_PASSWORD, CHECK_PASSWORD_TIP, SAME_PASSWORD_TIP } from '@/constants';

  @Component
  export default class Login extends Vue {
    loginForm = {
      email: '',
      password: ''
    }
    //修改密码的参数
    emailAddress = ''
    password = ''
    passwordConfirm = ''
    //注册的参数
    signupForm = {
      email: '',
      verification_code: '',
      password: '',
      password_confirmation: ''
    }

    sendTip = ''
    invalidFormat = ''
    hadSendEmail = false
    validateEmail = /^[A-Za-zd0-9]+([-_.][A-Za-zd0-9]+)*@([A-Za-zd0-9]+[-.])+[A-Za-zd]{2,5}$/;
    tip = 'Successfully sent, please check your email. \n A Friendly Reminder: If you don\'t get the email, please check your spam folder in case it goes there.';
    disabledCodeBtn = false
    resetPasswordTime = ''

    get type() {
      return this.$route.query.type || 'login';
    }

    get typeContent() {
      return {
        login: {
          title: 'Welcome!',
          subTitle: 'Log in to get started.',
          btnText: 'LOG IN',
          disabled: !_.every(this.loginForm)
        },
        fogot_password: {
          title: 'Forgot Password?',
          subTitle: 'Enter the email address you used when you joined and we’ll send you instructions to reset your password.',
          btnText: 'Send Reset Instructions',
          disabled: !this.emailAddress || this.hadSendEmail
        },
        reset_password: {
          title: 'Enter your new password below.',
          subTitle: '',
          btnText: 'Reset Password',
          disabled: !this.password || !this.passwordConfirm
        },
        signup: {
          title: 'Welcome!',
          subTitle: 'Signup to get started.',
          btnText: 'Sign Up',
          disabled: !_.every(this.signupForm)
        }
      }[this.type];
    }

    async created() {
      const { type } = this.$route.query;
      const { VUE_APP_ENV, VUE_APP_LOGIN_HOST } = process.env;
      const reg = new RegExp(`https?://${location.host}/?`);
      // 防止重置密码时，从邮箱跳转过来跳到当前有登陆的账号
      if (type === 'reset_password' || reg.test(VUE_APP_LOGIN_HOST)) {
        this.$authStore.logout();
      }
      // CAP 和 FJP 登录为统一域名，跳转登录页面，需要判断是否为登录页面
      if (VUE_APP_ENV !== 'development' && !reg.test(VUE_APP_LOGIN_HOST)) {
        location.href = urlJoin(process.env.VUE_APP_LOGIN_HOST, location.href.replace(reg, ''));
      }
    }

    handleClick() {
      if (this.typeContent.disabled) {
        return;
      }
      switch (this.type) {
        case 'login':
          this.handleLogin();
          return;
        case 'fogot_password':
          this.handleSendEmail();
          return;
        case 'reset_password':
          this.handleReset();
          return;
        case 'signup':
          this.handleSignup();
          return;
      }
    }

    // 登录
    async handleLogin() {
      const validator = new Schema({
        email: {
          required: true,
          type: 'email',
        },
        password: {
          required: true,
        }
      });

      try {
        await validator.validate(this.loginForm);
      } catch ({ errors }) {
        this.$message(errors[0].message);
        return;
      }

      await this.$autoLoading(this.$authStore.login(this.loginForm));
      this.$authStore.loginAfterNav();
    }

    // 忘记密码发送邮箱
    async handleSendEmail() {
      if (!this.validateEmail.test(this.emailAddress)) {
        this.$message('email is not a valid email');
        return;
      }
      try {
        await this.$request.post('passwords/forgot', { email: this.emailAddress });
      } catch (e) {
        this.$alertMsg(e.message);
        return;
      }
      this.$message({ message: this.tip, type: 'success' });
      this.sendTip = this.tip;
      this.hadSendEmail = true;
    }

    // 重设密码
    async handleReset() {
      if (!VALIDATE_PASSWORD.test(this.password)) {
        this.invalidFormat = CHECK_PASSWORD_TIP;
        await this.$message({ message: this.invalidFormat, type: 'error' });
        return;
      }
      if (this.password !== this.passwordConfirm) {
        this.$message(SAME_PASSWORD_TIP);
        return;
      }
      const { reset_password_token } = this.$route.query;
      try {
        await this.$request.put('passwords', {
          password: this.password,
          password_confirmation: this.passwordConfirm,
          reset_password_token
        });
      } catch (e) {
        this.$message(e.message);
        return;
      }
      this.$message({ message: 'Password changed successfully, please login again', type: 'success' });
      this.$router.replace({ name: 'login' });
    }

    // 注册时邮箱发送验证码
    async handleSendCode() {
      if (!this.validateEmail.test(this.signupForm.email)) {
        this.$alertMsg('email is not a valid email');
        return;
      }
      if (this.disabledCodeBtn) {
        return;
      }
      try {
        await this.$autoLoading(this.$request.post('verification_codes', {
          target: this.signupForm.email,
          event: 'sign_up'
        }));
        this.$message({ message: this.tip, type: 'success' });
        this.sendTip = this.tip;
        this.resetPasswordTime = Date.now();
      } catch (e) {
        this.sendTip = e.message;
      }
    }

    // 注册
    async handleSignup() {
      if (!VALIDATE_PASSWORD.test(this.signupForm.password)) {
        this.invalidFormat = CHECK_PASSWORD_TIP;
        await this.$message({ message: this.invalidFormat, type: 'error' });
        return;
      }
      if (this.signupForm.password !== this.signupForm.password_confirmation) {
        await this.$message({ message: SAME_PASSWORD_TIP, type: 'error' });
        return;
      }

      await this.$autoLoading(this.$authStore.registration(this.signupForm));
      await this.$message({ message: 'Registration succeeded', type: 'success' });
      this.$authStore.loginAfterNav();
    }

    handleChangeEmail() {
      this.sendTip = '';
      this.hadSendEmail = false;
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    background: var(--linear-gradient-purple);
  }

  .container {
    padding: 88px 0;

    @include media-xs-only {
      padding: 40px 16px 110px;
    }

    .left {
      width: 440px;
      color: var(--font-primary-white);

      .logo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 180px;
          height: auto;

          @include media-xs-only {
            width: 180px;
            height: auto;
          }
        }
      }

      .title {
        margin-top: 96px;
        font-weight: 500;
        font-size: 40px;
      }

      .sub-title {
        margin-top: 19px;
        font-weight: 400;
        font-size: 32px;
      }

      .input-group {
        margin-top: 58px;

        @include media-xs-only {
          margin-top: 52px;
        }

        .input {
          &:not(:first-child) {
            margin-top: 20px;
          }
        }

        .success-tip {
          white-space: pre-wrap;
          color: var(--font-primary-white);
        }
      }

      .guide {
        margin-top: 19px;

        a {
          display: block;
          text-decoration: underline;
        }

        a + a {
          margin-top: 11px;
        }
      }
    }

    .right {
      margin-top: 31px;
      font-weight: 500;
      color: var(--font-primary-white);

      .motto {
        max-width: 614px;
        font-size: 70px;
        opacity: 0.32;
      }

      .name {
        padding: 5px 180px 0 0;
        font-size: 24px;
        text-align: right;
        opacity: 0.6;
      }
    }
  }

  /deep/ .input {
    .input-name {
      padding: 0 0 8px;
      font-weight: 500;
      color: var(--font-primary-white);
    }

    .input-wrap {
      border: 1px solid var(--border-primary-purple);
      border-radius: 4px;
      background: var(--bg-secondary-white);
    }
  }

  /deep/ .ato-button {
    margin-top: 24px;
    border-radius: 4px;
  }

  .send-btn {
    margin-top: 14px;
  }
</style>
