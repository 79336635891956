<template>
  <div class="pagination" style="padding: 0.52466rem 0;">
    <el-pagination
      background
      :class="{'dark': $isDarkTheme}"
      layout="prev, pager, next"
      :page-size="store.meta.per_page"
      :current-page="currentPage"
      @current-change="handlePageChange"
      hide-on-single-page
      :total="store.meta.total">
    </el-pagination>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { Collection } from '@/stores';

  @Component
  export default class Pagination extends Vue {
    @Prop(Collection) store

    get currentPage() {
      return Number(this.$route.query.page || 1);
    }

    handlePageChange(page) {
      const { query } = this.$route;
      this.$router.replace({ query: { ...query, page } });
    }
  }
</script>

<style lang="scss" scoped>
  .pagination {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);

    @include media-xs-only {
      bottom: 100px;
    }
  }

  /deep/ .el-pagination {
    .btn-prev,
    .btn-next {
      font-size: 15px;
      color: var(--font-forth-blue);
      background: transparent;

      .el-icon {
        font-size: 25px;
      }
    }

    &.dark {
      .el-pager > li {
        border: none;
        color: var(--font-primary-black);
        background: var(--bg-secondary-gray);

        &:not(.disabled):hover {
          color: var(--font-forth-blue);
        }
      }
    }

    .el-pager > li {
      border: 2px solid var(--border-primary-gray);
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      color: var(--font-forth-blue);
      background: var(--bg-primary-white);
    }

    &.is-background .el-pager li:not(.disabled).active {
      background: var(--bg-primary-purple);
    }
  }

</style>
