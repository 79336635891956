<template>
  <div class="switch-wrapper" :class="{ active: value, disabled }">
    <div class="text-content flex item-center content-between">
      <div class="confirm" @click="toggle">Yes</div>
      <div class="cancel" @click="toggle">No</div>
    </div>
    <div class="switch-inner"></div>
  </div>
</template>

<script>
  import { Component, Vue, Prop, Model } from 'vue-property-decorator';

  @Component
  export default class PreferencesSwitch extends Vue {
    @Prop({ type: Boolean, default: false }) disabled
    @Model('input', { type: Boolean, default: false }) value

    toggle() {
      this.$emit('input', !this.value);
      this.$emit('click-change');
    }
  }
</script>

<style lang="scss">
  .switch-wrapper {
    position: relative;
    box-sizing: border-box;
    width: 250px;
    height: 58px;
    margin-bottom: 40px;
    border-radius: 29px;
    font-size: 24px;
    background: var(--bg-secondary-gray) 0% 0% no-repeat padding-box;
    transition: all .3s;
    cursor: pointer;

    .text-content {
      height: 58px;
      font-weight: 600;

      .confirm {
        z-index: 10;
        padding: 16px 40px;
        color: var(--font-secondary-black);
      }

      .cancel {
        z-index: 10;
        padding: 16px 40px;
        color: var(--font-third-gray);
      }
    }

    .switch-inner {
      position: absolute;
      top: 0;
      width: 125px;
      height: 58px;
      border-radius: 29px;
      background: var(--bg-primary-gray);
      transform: translateX(125px);
      transition: all .3s;
    }

    &.active {
      .switch-inner {
        background: var(--bg-primary-purple) 0% 0% no-repeat padding-box;
        transform: translateX(0);
      }

      .confirm {
        color: var(--font-primary-white);
      }

      .cancel {
        color: var(--font-primary-black);
      }
    }

    &.disabled {
      opacity: 0.6;
    }
  }
</style>
