<template>
  <div class="preferences">
    <div class="page-title">Your profile</div>
    <el-tabs v-model="activeName" @tab-click="handleTypeClick">
      <el-tab-pane label="Personal info" name="personal">
        <personal-info/>
      </el-tab-pane>
      <el-tab-pane label="Contact info" name="contact">
        <contact-info/>
      </el-tab-pane>
      <el-tab-pane label="Password" name="password">
        <common-prod-password-group/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import PersonalInfo from './components/personal-info';
  import ContactInfo from './components/contact-info';
  import _ from 'lodash';

  @Component({
    components: {
      'personal-info': PersonalInfo,
      'contact-info': ContactInfo
    }
  })
  export default class Profile extends Vue {
    activeName = this.$route.query.type || 'personal'

    async handleTypeClick() {
      if (this.$route.query.type !== this.activeName) {
        this.$router.replace({ query: _.merge({}, { type: this.activeName }) });
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .el-tabs__header {
    .el-tabs__nav-wrap {
      &::after {
        display: none;
      }

      &.is-scrollable {
        padding: 0;
      }
    }

    .el-tabs__nav {
      transition: none;
    }

    .el-tabs__nav-scroll {
      overflow: auto;
    }

    .el-tabs__nav-prev {
      display: none;
    }

    .el-tabs__nav-next {
      display: none;
    }

    .el-tabs__item {
      padding: 0 40px;
      font-size: 24px;
      color: var(--font-primary-black);

      &.is-active {
        color: var(--font-primary-purple);
      }

      @include media-xs-only {
        padding: 0 30px;
      }
    }

    .el-tabs__nav .el-tabs__active-bar {
      height: 4px;
      background: var(--font-primary-purple);
      transition: none;

      @include media-sm-and-up {
        width: 183px !important;
      }
    }
  }

  .info-wrap {
    margin-top: 50px;

    @include media-xs-only {
      margin-top: 26px;
    }

    /deep/ .input-group {
      padding-bottom: 30px;
    }
  }

  /deep/ .footer {
    width: 316px;
    margin-top: 10px;

    @include media-xs-only {
      width: 100%;
    }

    .tip {
      font-weight: 500;
      font-size: 18px;
      color: var(--font-primary-purple);
      cursor: pointer;
    }

    .ato-button--large {
      width: 316px;

      @include media-xs-only {
        box-sizing: border-box;
        width: 100%;
        height: 48px;
      }
    }
  }
</style>
