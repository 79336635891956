<template>
  <div v-if="currentTime" class="clock flex item-center">
    <div class="hour">
      <img :src="hourImgs[0]" alt="">
      <img :src="hourImgs[1]" alt="">
    </div>
    <span class="colon">:</span>
    <div class="minute">
      <img :src="minuteImgs[0]" alt="">
      <img :src="minuteImgs[1]" alt="">
    </div>
    <span class="meridiem self-end" :class="{ 'dark-color': $isDarkTheme }">
      {{ meridiem }} {{ $authStore.isAdvisor ? `(UTC ${utcOffset})` : '' }}
    </span>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import dayjs from 'dayjs';
  import timezone from 'dayjs/plugin/timezone';

  dayjs.extend(timezone);

  @Component
  export default class Clock extends Vue {
    @Prop(String) timezone

    timer = ''
    currentTime = ''
    utcOffset = null

    get clockImgs() {
      if (this.$isDarkTheme) {
        return [
        require('@/assets/clock/dark-0.png'),
        require('@/assets/clock/dark-1.png'),
        require('@/assets/clock/dark-2.png'),
        require('@/assets/clock/dark-3.png'),
        require('@/assets/clock/dark-4.png'),
        require('@/assets/clock/dark-5.png'),
        require('@/assets/clock/dark-6.png'),
        require('@/assets/clock/dark-7.png'),
        require('@/assets/clock/dark-8.png'),
        require('@/assets/clock/dark-9.png')
        ];
      }
      return [
        require('@/assets/clock/0.png'),
        require('@/assets/clock/1.png'),
        require('@/assets/clock/2.png'),
        require('@/assets/clock/3.png'),
        require('@/assets/clock/4.png'),
        require('@/assets/clock/5.png'),
        require('@/assets/clock/6.png'),
        require('@/assets/clock/7.png'),
        require('@/assets/clock/8.png'),
        require('@/assets/clock/9.png')
      ];
    }

    get hourImgs() {
      const hour = this.currentTime.format('hh');
      return [this.clockImgs[hour[0]], this.clockImgs[hour[1]]];
    }

    get minuteImgs() {
      const minute = this.currentTime.format('mm');
      return [this.clockImgs[minute[0]], this.clockImgs[minute[1]]];
    }

    get meridiem() {
      return this.currentTime.format('A');
    }

    @Watch('timezone', { immediate: true })
    timezoneChange() {
      this.currentTime = dayjs().tz(this.timezone);
      this.utcOffset = this.currentTime.format('Z');

      this.timer = setInterval(() => {
        this.currentTime = this.currentTime.add(1, 'second');
      }, 1000);
    }

    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .clock {
    color: $primary-purple;

    img {
      height: 20px;
    }

    img + img {
      margin-left: 5px;
    }

    .colon {
      margin: -5px 3px 0;
      font-size: 25px;
    }

    .meridiem {
      margin-left: 8px;
      font-size: 16px;

      &.dark-color {
        color: #7f57de;
      }
    }
  }
</style>
