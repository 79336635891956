<template>
  <div v-if="detail.id" class="page">
    <router-link :to="{ name: 'students' }" class="back-btn">
      Back to all students
    </router-link>

    <common-prod-card class="profile-card">
      <div class="flex item-center">
        <img class="avatar" :src="$get(detail, 'avatar.url') || require('@/assets/default-avatar.png')" alt="">
        <div class="content flex column flex-1">
          <div class="info flex-1 flex">
            <InfoLine :data="detail" :show-avatar="false" />
          </div>
          <div v-if="$get(detail, 'profile.grade')" class="info">
            <strong>Grade: </strong>
            <span>{{ detail.profile.grade }}</span>
          </div>
          <div v-if="$get(detail, 'profile.year_of_graduation')" class="info">
            <strong>Graduation year: </strong>
            <span>{{ detail.profile.year_of_graduation }}</span>
          </div>
        </div>
      </div>
    </common-prod-card>

    <div class="card-group">
      <common-prod-card title="about">
        <div v-if="$get(detail, 'profile.high_school')" class="about-item flex item-center">
          <img src="@/assets/message/purple-school.png" alt="">
          <span>{{ detail.profile.high_school }}</span>
        </div>
        <div v-if="location" class="about-item flex item-center">
          <img src="@/assets/message/purple-location.png" alt="">
          <span>{{ location }}</span>
        </div>
        <div v-if="$get(detail, 'contact.time_zone')" class="about-item flex item-center">
          <img src="@/assets/message/purple-pending.png" alt="">
          <span>{{ detail.contact.time_zone }}</span>
        </div>
      </common-prod-card>
      <common-prod-card v-if="$get(detail, 'contact.guardians.length')" title="parent/guardian(s)">
        <div class="guardians-box">
          <div v-for="guardian in $get(detail, 'contact.guardians')" :key="guardian.id" class="guardian-item">
            <span v-if="guardian.name">{{ guardian.name }}</span>
            <a v-if="guardian.email" :href="`mailto:${guardian.email}`" class="link">
              {{ guardian.email }}
            </a>
            <a v-if="guardian.phone" :href="`tel:${guardian.phone}`" class="link">
              {{ guardian.phone }}
            </a>
          </div>
        </div>
      </common-prod-card>
    </div>

    <common-prod-card title="Head Advisor">
      <InfoLine :data="detail.head_advisor" />
    </common-prod-card>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import InfoLine from './components/info-line.vue';

  @Component({
    components: {
      InfoLine,
    },
  })
  export default class Detail extends Vue {
    detail = {}

    get location() {
      const { country, state, city } = this.detail.profile || {};
      return [country, state, city].map(item => this.$get(item, 'name')).filter(Boolean).join(', ');
    }

    @Vue.autoLoadingProgress
    async created() {
      const { data } = await this.$request.get('mine/student_detail', { params: { id: this.$route.params.id } });
      this.detail = data;
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    gap: 48px;

    @include media-lg-and-down {
      gap: 16px;
    }

    .back-btn {
      font-size: 18px;
      text-decoration: underline;
      color: var(--font-seventh-purple);
    }

    ::v-deep .card {
      box-sizing: border-box;
      width: 55%;
      padding: 16px;
      border-radius: 8px;

      @include media-lg-and-down {
        width: 100%;
      }

      .body {
        padding: 31px 0 14px;
      }
    }

    .profile-card {
      width: 80%;

      @include media-lg-and-down {
        width: 100%;
      }

      .avatar {
        width: 100px;
        height: 100px;
      }

      .content {
        margin-left: 32px;
      }

      ::v-deep .info-line {
        .name {
          margin-left: 0;
        }
      }

      .info {
        font-size: 18px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }

    .card-group {
      display: flex;
      justify-content: space-between;
      width: 80%;
      font-size: 18px;
      gap: 48px;

      @include media-lg-and-down {
        flex-direction: column;
        width: 100%;
        gap: 16px;
      }

      ::v-deep .card {
        flex: 1;
        width: 100%;
        overflow: hidden;
      }

      .about-item {
        color: var(--font-primary-black);

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }

      .guardians-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .guardian-item {
          display: flex;
          flex-direction: column;
          gap: 10px;
          overflow: hidden;
        }
      }

      .link {
        overflow: hidden;
        font-style: italic;
        text-overflow: ellipsis;
        text-decoration: underline;
        color: var(--font-primary-purple);
      }
    }
  }
</style>
