<template>
  <div>
    <router-link :to="{ name: 'tasks' }" class="back-btn">Back to all assignments</router-link>
    <div class="task-wrapper">
      <div class="task-content flex item-center">
        <!-- left -->
        <div class="left flex item-center flex-1">
          <div class="title-wrap flex item-center">
            <div class="title">{{ task.name }}</div>
          </div>
          <common-prod-tag v-if="$get(task, 'school_student.school.name')" :toggle="false" :tag-text="$get(task, 'school_student.school.name')"/>
          <div class="operation">
            <span @click="showEditTask = true" class="operation-btn">Edit</span>
            <span
              v-if="task.status"
              @click="handleTaskState"
              class="operation-btn"
              :class="{ 'disabled': task.status === 'wait_advisor_review' && !$get($authStore, 'isAdvisor') }"
            >
              <span v-if="task.status === 'init'">{{ $get($authStore, 'isAdvisor') ? 'Mark as complete' : 'I\'m Done' }}</span>
              <span v-else-if="task.status === 'wait_advisor_review'">{{ $get($authStore, 'isAdvisor') ? 'Mark as complete' : 'Waiting for advisor review' }}</span>
              <span v-else>re-open</span>
            </span>
            <span v-if="showDeleteBtn" @click="handleDelete" class="operation-btn">Delete</span>
          </div>
        </div>
        <!-- right -->
        <div class="right column item-center">
          <div v-if="task.due_at" class="time">(Due {{ task.due_at | formatTime }})</div>
        </div>
      </div>
      <common-vditor :value="task.desc" only-read/>
    </div>
    <common-prod-card title="FILES" class="files-wrapper">
      <!-- 文件列表 -->
      <common-prod-file-lists :files="files" @delete-item="handleDeleteFile"/>
      <div class="flex content-end" v-if="$get(this.task, 'documents_attributes.length') > 2">
        <span class="link" @click="showAllFile = !showAllFile">{{showAllFile ? 'Hide files' : 'All files'}}</span>
      </div>

      <common-prod-upload-input
        label=""
        :show-files="false"
        @upload-success="handleAddFile"
        sizeTip="(A single file cannot exceed 100 MB)"
      >
        <common-prod-button class="relative">UPLOAD A FILE </common-prod-button>
      </common-prod-upload-input>
      <!-- 新上传的文件 -->
      <div class="flex item-center item" v-for="(item, key) in progress" :key="key">
        <common-prod-list-line :text="item.filename"/>
        <el-progress :percentage="item.progress" style="width: 20.98636rem"/>
      </div>
    </common-prod-card>
    <common-prod-card title="COMMENTS" class="comments-wrapper">
      <div class="comment-list">
        <div class="flex comment-item" v-for="item in commentsStore.data" :key="item.id">
          <img :src="$get(item, 'creator.avatar.url') || require('@/assets/default-avatar.png')" alt="" class="avatar">
          <div class="flex-1" style="overflow: hidden;">
            <div class="item-header flex item-baseline font-regular">
              <div class="username">{{ $get(item, 'creator_id') === $get($authStore, 'user.id') ? 'You' : item.creator.name }}</div>
              <div class="comment-time">{{ item.updated_at | formatTime }}</div>
              <span v-if="$get(item, 'creator_id') === $get($authStore, 'user.id')" class="edit-btn" @click="editComment = item">Edit</span>
            </div>
            <div class="message">
              <common-vditor :value="item.content" only-read/>
              <common-prod-file-lists
                :files="item.documents_attributes"
                @delete-item="handleDeleteCommentFile($event, item)"
                :isNormal="false"
              />
            </div>
          </div>
        </div>
      </div>
      <comment-form :submit="handleSubmitComment"/>
    </common-prod-card>
    <common-popup :show="!!editComment" @close="editComment = null" title="Edit a comment">
      <comment-form :submit="($event) => handleSubmitComment($event, editComment)" type="Edit" :value="editComment"/>
    </common-popup>
    <common-popup v-model="showEditTask" title="Edit an assignment">
      <task-form :task="task" isEdit :submit="handleEdit" @close="showEditTask = false"/>
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import _ from 'lodash';
  import CommentForm from './components/comment-form';
  import TaskForm from './components/task-form.vue';

  @Component({
    components: {
      'comment-form': CommentForm,
      'task-form': TaskForm
    }
  })
  export default class TaskDetail extends Vue {
    task = {}
    progress = {}
    showEditTask = false
    showAllFile = false
    editComment = null
    commentsStore = this.$Collection.create({
      fetch: (params) => {
        return this.$request.get('comments', { params: {
          ...params,
          commentable_type_eq: 'Task',
          commentable_id_eq: this.$route.params.id,
        } });
      }
    })

    get files() {
      const files = _.get(this.task, 'documents_attributes') || [];
      return this.showAllFile ? files : files.slice(0, 2);
    }

    get showDeleteBtn() {
      // 学生可以删除自己新建的任务的，advisor能删除自己还有学生的任务的。
      if (this.$authStore.isAdvisor) {
        return true;
      }
      return this.$get(this.task, 'creator.id') === this.$get(this.$authStore, 'user.id');
    }

    @Vue.autoLoadingProgress
    async created() {
      return Promise.all([
        this.fetchTask(),
        this.commentsStore.fetchData()
      ]).catch((e) => {
        // 该任务删除之后会找不到，去到404页面
        if (e.status === 404) {
          this.$router.replace({ name: '404' });
        }
      });
    }

    async fetchTask() {
      const { data } = await this.$request.get(`tasks/${this.$route.params.id}`);
      this.task = data;
    }

    async handleAddFile(file) {
      const { data } = await this.$request.post('documents', {
        attachable_type: 'Task',
        attachable_id: this.$route.params.id,
        attachments: [file.signed_id]
      });
      this.task.documents_attributes.push(...data);
    }

    async handleTaskState() {
      const isAdvisor = this.$authStore.isAdvisor;

      if (this.task.status === 'completed') {
        await this.$confirm('Are you sure you want to reopen this assignment?', 'Confirmation');
        const { data } = await this.$request.put(`/tasks/${this.$route.params.id}/reopen`);
        this.task = data;
        return;
      }

      const title = isAdvisor ? 'Confirmation' : 'Done?';
      const text = isAdvisor ? `Confirm that ${this.$get(this.$authStore, 'user.current_role.name')} has completed this assignments?` : 'Submit all files associated with this assignment to your advisor for review?';
      const cancelText = isAdvisor ? 'No, it still needs work' : 'No, I forgot something';
      await this.$confirm(text, title, {
        confirmButtonText: 'YES',
        cancelButtonText: cancelText
      });

      if (isAdvisor) {
        const { data } = await this.$request.put(`tasks/${this.task.id}/advisor_complete`);
        this.task = data;
      } else {
        const { data } = await this.$request.put(`tasks/${this.task.id}/student_complete`);
        this.task = data;
      }
    }

    async handleDelete() {
      await this.$confirm('Are you sure to delete this assignment?');
      await this.$autoLoading(this.$request.delete(`tasks/${this.$route.params.id}`));
      this.$message({ message: 'Congratulations, the assignment is deleted!', type: 'success' });
      this.$router.replace({ name: 'tasks' });
    }

    @Vue.autoLoading
    async handleEdit(body) {
      const { data } =  await this.$request.put(`/tasks/${this.$route.params.id}`, _.omit(body, ['documents_attributes']));
      this.task = data;
    }

    @Vue.autoLoading
    async handleDeleteFile(file) {
      await this.$request.delete(`documents/${file.id}`);
      const index = this.task.documents_attributes.findIndex(item => item.id === file.id);
      this.task.documents_attributes.splice(index, 1);
    }

    @Vue.autoLoading
    async handleDeleteCommentFile(file, comment) {
      const signedId = file.attachment.signed_id;
      const { data } = await this.$request.put(`comments/${comment.id}`, { documents_attributes: [
        {
          id: file.id,
          attachment: signedId,
          _destroy: true
        }
      ] });
      this.commentsStore.replaceItem(data);
    }

    // 提交评论
    @Vue.autoLoading
    async handleSubmitComment(body, old) {
      const taskId = this.$route.params.id;
      if (_.get(old, 'id')) {
        const { data } = await this.$request.put(`comments/${old.id}`, { ...body });
        this.commentsStore.replaceItem(data);
        this.editComment = null;
      } else {
        const { data } = await this.$request.post('comments', {
          ...body,
          commentable_type: 'Task',
          commentable_id: taskId
        });
        this.commentsStore.unshift(data);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .back-btn {
    font-size: 18px;
    text-decoration: underline;
    color: var(--font-seventh-purple);
  }

  .task-wrapper {
    margin: 15px 0 50px;

    .task-content {
      margin-bottom: 24px;

      @include media-xs-only {
        display: block;
      }
    }

    .left {
      flex-wrap: wrap;

      @include media-xs-only {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 12px;
      }

      .operation {
        i {
          font-size: 20px;
        }

        @include media-xs-only {
          display: block;
        }
      }

      .checkbox {
        width: 17px;
        height: 17px;
        margin-right: 33px;
        border: 2px solid var(--border-seventh-purple);
        background: transparent;
        transition: all .3s;
        cursor: pointer;

        &.finish {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid var(--border-fifth-gray);
          text-align: center;
          vertical-align: middle;
          background: var(--bg-fifth-gray);

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .title-wrap {
        max-width: 60%;

        @include media-xs-only {
          max-width: 100%;
        }

        .title {
          margin-right: 22px;
          font-size: 40px;
          line-height: 40px;
          color: var(--font-secondary-black);

          @include media-xs-only {
            font-size: 32px;
          }
        }
      }

      /deep/ .tag {
        margin-right: 12px;
      }

      .operation-btn {
        display: inline-block;
        height: 40px;
        padding: 0 30px;
        margin-right: 23px;
        border-radius: 13px;
        line-height: 40px;
        text-align: center;
        color: var(--font-primary-black);
        background: var(--bg-primary-gray);
        cursor: pointer;

        @include media-xs-only {
          margin-top: 5px;
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
    }

    .right {
      text-align: center;

      @include media-xs-only {
        text-align: right;
      }

      .time {
        font-size: 16px;
        color: var(--font-primary-blue);
      }
    }

    .desc {
      max-width: 80%;
      margin-top: 24px;
      font-size: 18px;
      white-space: pre-wrap;
      color: var(--font-primary-blue);
    }
  }

  .files-wrapper {
    margin-bottom: 30px;

    .hide-input {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .link {
      font-size: 18px;
      text-decoration: underline;
      color: var(--font-seventh-purple);
      cursor: pointer;
    }

    /deep/ .upload-btn-wrapper {
      margin: 0;
    }
  }

  .comments-wrapper {
    color: var(--font-secondary-black);

    .comment-item {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 50px;
      }

      @include media-xs-only {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 30px;
        }
      }

      .item-header {
        @include media-xs-only {
          flex-wrap: wrap;
        }
      }

      /deep/ .item {
        margin: 12px 0 0;
      }
    }

    .avatar {
      width: 50px;
      height: 50px;
      margin-right: 16px;
      border-radius: 50%;
      object-fit: cover;
    }

    .username {
      margin-top: 10px;
      margin-right: 13px;
      font-size: 24px;

      @include media-xs-only {
        width: 100%;
        margin: 0 0 10px;
      }
    }

    .comment-time {
      font-size: 16px;
    }

    .edit-btn {
      margin-left: 10px;
      font-size: 16px;
      text-decoration: underline;
      color: var(--font-seventh-purple);
      cursor: pointer;
    }

    .message {
      margin-top: 12px;
      font-size: 18px;
    }
  }
</style>
