<template>
  <div class="filter-group-wrapper">
    <template v-for="group in groups">
      <div class="tag-container flex item-center" :key="group.key" v-if="$get(group, 'data.length')">
        <div class="name">{{ group.title }}</div>
        <div class="tag-group flex-1">
          <span v-if="group.allBtn" >
            <common-prod-tag
              tag-text="All"
              :active="all[group.key]"
              @change="handleToggleAll(group)"
            />
          </span>
          <span v-for="item in group.data" :key="item.value" >
            <el-tooltip class="item" effect="dark" :content="item.label" placement="top-start" :open-delay="1000">
              <common-prod-tag
                :tag-text="item.label"
                :active="!!activeTags[group.key][item.value]"
                @change="handleFilterChange(group, item.value, $event)"
              />
            </el-tooltip>
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import _ from 'lodash';
  import { getQueryArray } from '@/utils';

  @Component
  export default class Filters extends Vue {
    /**
     * [
     *  {
     *    key: 'school_student_id', // query key
     *    title: 'By school',
     *    data: [{label, value}]
     *  }
     * ]
     */
    @Prop({ type: Array, default: () => [] }) groups

    get activeTags() {
      const obj = {};
      this.groups.map(group => {
        obj[group.key] = {};
        const includeQuery = getQueryArray(`${group.key}_in`);
        if (includeQuery.length) {
          _.map(group.data, ({ value }) => {
            obj[group.key][value] = includeQuery.includes(String(value));
          });
        }
      });
      return obj;
    }

    get all() {
      const obj = {};
      this.groups.map(group => {
        const dataValue = group.data.map(({ value }) => String(value));
        obj[group.key] = _.difference(dataValue, getQueryArray(`${group.key}_in`)).length === dataValue.length;
      });
      return obj;
    }

    handleFilterChange(group, key, val) {
      const includeKey = `${group.key}_in`;
      let includeQuery = getQueryArray(includeKey);
      const query = {
        ...this.$route.query,
        page: 1,
      };
      if (val) {
        query[includeKey] = [...includeQuery, key];
      } else {
        _.remove(includeQuery, item => String(item) === String(key));
        query[includeKey] = includeQuery;
      }
      this.$router.replace({ query });
    }

    handleToggleAll(group) {
      if (!this.all[group.key]) {
        const query = {
          ...this.$route.query,
          page: 1,
        };
        query[`${group.key}_in`] = undefined;
        this.$router.replace({ query });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .filter-group-wrapper {
    margin-bottom: 42px;

    .tag-container {
      margin-bottom: 13px;

      @include media-xs-only {
        display: block;
        margin-bottom: 10px;
      }

      .name {
        width: 100px;
        margin-bottom: 10px;
        font-size: 16px;
        color: var(--font-primary-blue);

        @include media-xs-only {
          padding-bottom: 6px;
          font-size: 18px;
        }
      }

      .tag-group {
        margin-left: 30px;

        @include media-xs-only {
          margin: 10px 0 0;
        }

        .tag {
          margin-bottom: 10px;
        }

        .tag:first-of-type {
          margin-right: 12px;
        }

        .tag + .tag {
          margin-right: 12px;
        }
      }
    }

  .list-wrap {
    margin-bottom: 54px;
    color: var(--font-seventh-purple);

    .list-content {
      margin-bottom: 25px;
    }
  }
}
</style>
