<template>
  <div class="not-find flex item-center content-center">
    <div class="box flex column item-center">
      <img class="icon" src="@/assets/404-icon.png" alt="">
      <div class="title">404</div>
      <div>Page Not Found</div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class NotFindPage extends Vue {}
</script>

<style lang="scss" scoped>
  .not-find {
    height: 100vh;
    background: var(--bg-primary-gray);

    .box {
      padding: 63px;
      border-radius: 57px;
      font-size: 18px;
      color: var(--font-forth-gray);
      background: var(--bg-primary-white);

      .icon {
        width: 50px;
        height: 42px;
      }

      .title {
        font-weight: 600px;
        font-size: 80px;
      }
    }
  }
</style>
