<template>
  <div class="common-layout" :style="{'--screen-height': $data.screenHeight}">
    <component-sidebar/>
    <div class="container">
      <div v-if="$authStore.isAdvisor" class="tip">You are logged in as an advisor.</div>
      <component-header/>
      <main class="main">
        <div class="content"><slot/></div>
      </main>
    </div>
    <component-help v-if="!$authStore.isAdvisor"/>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import Header from './header';
  import Sidebar from './sidebar';
  import Help from './help';

  @Component({
    components: {
      'component-sidebar': Sidebar,
      'component-help': Help,
      'component-header': Header,
    }
  })
  export default class CommonLayout extends Vue {
    mounted() {
      this.$initScreenHeight();
    }

    beforeDestroy() {
      this.$destroyScreenHeight();
    }
  }
</script>

<style lang="scss" scoped>
  .common-layout {
    display: flex;
    box-sizing: border-box;
    height: 100vh;
    background-color: var(--bg-secondary-white);

    @include media-xs-only {
      flex-direction: column-reverse;
      height: var(--screen-height);
    }

    .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;

      .tip {
        padding: 15px 40px;
        font-style: italic;
        font-size: 20px;
        text-align: right;
        color: var(--font-primary-white);
        background: #270f43;
      }

      .main {
        flex: 1;
        overflow: auto;

        > .content {
          position: relative;
          box-sizing: border-box;
          min-height: 100%;
          padding: 50px 40px;

          @include media-xs-only {
            padding: 40px 16px;
          }
        }
      }
    }
  }
</style>
