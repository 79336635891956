<template>
  <div class="">
    <div class="flex title-wrapper">
      <div class="">
        <img src="@/assets/icon-good.png" class="icon hidden-xs-only">
      </div>
      <div class="">
        <div class="page-title">{{ pageTitle }}</div>
        <div class="sub-title" v-if="$get(authStore, 'user.role') === 'student'">
          <template v-if="$get(authStore, 'user.head_advisor.name')">
            <span>Your head advisor is</span>
            <span class="primary-red"> {{ $get(authStore, 'user.head_advisor.name') }}</span>
            <span>. </span>
          </template>
          <template v-if="$get(this.$authStore.user, 'extended_info.show_quick_tutorial')">
            <span>Since you're new, </span>
            <span @click="handleShowVideo" class="link">watch a quick tutorial!</span>
          </template>
          <!-- TODO: 已填写相关资料判断 -->
          <!-- <template>
            <span>Need some </span>
            <span @click="handleShowVideo" class="link">motivation</span>
            <span> today?</span>
          </template> -->
        </div>
      </div>
    </div>
    <div class="categories-wrapper">
      <div class="title">
          <span>{{ tasksTip }}</span>
          <router-link :to="{ name: 'tasks' }" class="link no-underline"> {{ $get(authStore, 'user.recent_week_active_tasks_count', 0) | pluralize('assignment', true, 'no') }} due</router-link>
          <span> this week</span>
      </div>
      <div class="cards-wrapper">
        <div v-for="group in categories" :key="group.id" class="card" :class="{ active: group.active_task }">
          <div class="title uppercase text-overflow">{{ group.name }}</div>
          <div class="content" v-if="group.active_task">
            <div class="item flex item-center">
              <common-prod-list-line
                :time="`(${getTaskTimeText(group.active_task)})`"
                :text="$get(group, 'active_task.name')"
                :to="{name: 'task.detail', params: { id: $get(group, 'active_task.id') }}"
              />
            </div>
          </div>
          <div class="content" v-else>
            <span class="text">Nothing to see here!</span>
          </div>
          <div class="flex content-end">
            <router-link :to="{ name: 'tasks', query: { task_category_id_in: group.id } }" class="link">
              All {{ group.name.toLowerCase() }} assignments
            </router-link>
          </div>
        </div>
        <div v-if="onboardingTask" class="card active">
          <div class="title uppercase text-overflow">Miscellaneous</div>
          <div class="content">
            <div class="item flex item-center">
              <common-prod-list-line
                :time="`(${getTaskTimeText(onboardingTask)})`"
                :text="$get(onboardingTask, 'name')"
                :to="{name: 'task.detail', params: { id: $get(onboardingTask, 'id') }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <common-prod-card title="RECENT ACTIVITY">
      <common-prod-activity-msg welcome v-if="showWelcome"/>
      <div v-if="authStore.isAdvisor && !$get(authStore, 'user.students.length')" class="empty-tip">You have no students at present.</div>
      <common-prod-activity-msg
        v-for="item in activitiesStore.data"
        :key="item.id"
        :activity="item"
      />
      <common-prod-button v-if="!activitiesStore.isComplete" @click.native="handleLoadMore(activitiesStore)">LOAD MORE</common-prod-button>
    </common-prod-card>
    <guide-tips v-model="showGuide" @guide-btn="handleClickGuideBtn" />
    <guide-vedio v-model="showVideo" />
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import GuideTips from './components/guide';
  import GuideVedio from './components/guide-vedio';
  import { authStore } from '@/stores';
  import dayjs from 'dayjs';

  @Component({
    components: {
      'guide-tips': GuideTips,
      'guide-vedio': GuideVedio
    }
  })
  export default class Dashboard extends Vue {
    showGuide = false
    showVideo = false
    authStore = authStore
    categories = []
    onboardingTask = null
    activitiesStore = this.$Collection.create({
      fetch: params => this.$request.get('activities', { params })
    })

    get pageTitle() {
      let dateText = '';
      const h = dayjs().tz(this.$authStore.studentTimeZone).get('h');
      if (h >= 5 && h < 12) {
        dateText = 'morning';
      } else if (h >= 12 && h < 18) {
        dateText = 'afternoon';
      } else if (h >= 18 && h < 22) {
        dateText = 'evening';
      } else if ((h >= 22 && h < 24) || h < 5) {
        dateText = 'night';
      }
      return `Good ${dateText}, ${this.$get(authStore, 'user.name')}.`;
    }

    get tasksTip() {
      if (authStore.isAdvisor) {
        return this.$get(this.$authStore, 'user.students.length') ? `This is ${this.$get(this.$authStore, 'user.current_role.name')}'s dashboard. ${this.$get(this.$authStore, 'user.current_role.name')} has` : 'This is your student\'s dashboard. Your student has';
      } else {
        return 'This is your dashboard. You have';
      }
    }

    get showWelcome() {
      if (this.activitiesStore.isEmpty) {
        return authStore.isAdvisor ? authStore.user.students.length : true;
      } else {
        return false;
      }
    }

    @Vue.autoLoadingProgress
    async created() {
      await Promise.all([
        authStore.tryFetchData(),
        this.activitiesStore.fetchData(),
        this.fetchTasks(),
      ]);

      if (!authStore.isAdvisor) {
        await this.fetchOnboardingTask();
      }

      // 学生注册后，如果超过两星期，则不再显示视频指引
      if (this.$get(this.$authStore.user, 'extended_info.show_quick_tutorial')) {
        if (dayjs().isAfter(dayjs(authStore.user.created_at).add(2, 'week'))) {
          this.$authStore.updateIsShow('show_quick_tutorial', false);
        }
      }

      // 只有学生，且没有点击不再提示按钮才会显示导航指引
      this.showGuide = !this.$authStore.isAdvisor && this.$get(this.$authStore.user, 'extended_info.show_tool_guide');
    }

    async fetchTasks() {
      const { data } = await this.$request.get('task_categories/list_with_active_task');
      this.categories = data;
    }

    async fetchOnboardingTask() {
      const { data } = await this.$request.get('mine/onboarding_task');
      this.onboardingTask = data;
    }
    // 任务时间描述
    getTaskTimeText({ due_at }) {
      const currentWeek = dayjs().week();
      const w = dayjs(due_at).week();
      if (currentWeek === w) {
        const day = dayjs(due_at).day();
        return day ? `Due in ${day} days` : 'today';
      }
      return `Due ${dayjs(due_at).tz(authStore.timeZone).format('MMMM D, YYYY h:mm A')}`;
    }

    getTaskDays(task) {
      return dayjs().diff(task.due_at, 'day');
    }

    @Vue.autoLoading
    handleLoadMore(store) {
      return store.fetchMoreData();
    }

    async handleShowVideo() {
      this.showVideo = true;
    }

    handleClickGuideBtn(val) {
      switch (val) {
        case 'toWork':
          this.$authStore.updateIsShow('show_quick_tutorial', false);
          break;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .title-wrapper {
    .icon {
      width: 54px;
      height: 54px;
      margin-top: 5px;
      margin-right: 30px;
    }

    .page-title {
      margin-bottom: 0;
    }

    .sub-title {
      padding-top: 16px;
      font-size: 36px;
      color: var(--font-secondary-black);

      @include media-xs-only {
        font-size: 28px;
      }

      .primary-red {
        color: var(--font-primary-red);
      }

      .link {
        text-decoration: underline;
        color: var(--font-primary-purple);
        cursor: pointer;
      }
    }
  }

  .categories-wrapper {
    margin: 56px 0;

    @include media-xs-only {
      margin-top: 24px;
    }

    .title {
      font-size: 24px;
      line-height: 34px;

      .link {
        text-decoration: underline;
        color: var(--font-primary-purple);

        &.no-underline {
          text-decoration: none;
        }
      }
    }

    .cards-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 2fr);
      gap: 24px;
      margin-top: 24px;

      @include media-xs-only {
        grid-template-columns: repeat(1, 1fr);
      }

      .card {
        padding: 16px 19px 19px;
        border-radius: 8px;
        color: var(--font-primary-white);
        background: var(--bg-secondary-gray);

        &.active {
          background: var(--radial-gradient-blue);

          .link {
            color: var(--font-primary-white);
          }
        }

        .title {
          font-size: 18px;
          font-family: 'Radwave-Regular';
        }

        /deep/ .list-desc {
          width: auto;
          max-width: 270px;

          @include media-md-and-up {
            max-width: 330px;
          }
        }

        .content {
          box-sizing: border-box;
          margin: 18px 0;

          .text {
            display: inline-block;
            box-sizing: border-box;
            padding-bottom: 3px;
            font-size: 22px;

            @include media-lg-and-down {
              padding-bottom: 32px;
            }
          }
        }

        .link {
          font-size: 16px;
          text-decoration: underline;
          color: var(--font-primary-purple);
        }
      }
    }
  }

  .empty-tip {
    font-size: 24px;
    text-align: center;
    color: var(--font-forth-blue);
  }

  /deep/.activity-msg-wrapper {
    margin-bottom: 40px;

    @include media-xs-only {
      margin-bottom: 21px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  div /deep/ .list-container {
    .checkbox {
      margin-right: 17px;
      border: 2px solid var(--border-primary-white);
    }

    .content .due-time {
      margin-left: 6px;
      font-size: 16px;
      color: var(--font-primary-white);

      @include media-lg-and-down {
        margin-left: 0;
      }
    }
  }
</style>
