<template>
  <div class="start-page">
    <setting-nickname v-if="step === 'settingNickname'"/>
    <info-page v-else-if="step === 'info'"/>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import settingNickname from './components/setting-nickname.vue';
  import infoPage from './components/info-page.vue';

  @Component({
    components: {
      'setting-nickname': settingNickname,
      'info-page': infoPage
    }
  })
  export default class StartPage extends Vue {
    step = this.$route.query.step || 'info'

    @Vue.autoLoadingProgress
    async created() {
      if (this.$route.query.step !== this.step) {
        this.$router.push({ query: { ...this.$route.query, step: this.step } });
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
