<template>
  <div class="info">
    <common-started-layout>
      <div class="container flex column item-center content-center">
        <div class="title">Help us make sure your information is correct</div>
        <div class="input-group">
          <el-row :gutter="24">
            <el-col :sm="12" :xs="24">
              <common-prod-input
                v-model.trim="infoForm.first_name"
                inputTitle="First Name"
                @keyup.enter.native="handleLook"
              />
            </el-col>
            <el-col :sm="12" :xs="24">
              <common-prod-input
                v-model.trim="infoForm.last_name"
                inputTitle="Last Name"
                @keyup.enter.native="handleLook"
              />
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :sm="12" :xs="24">
              <common-prod-input
                v-model.trim="infoForm.high_school"
                inputTitle="School"
                @input="handleChangeValue"
                @keyup.enter.native="handleLook"
              />
              <div v-show="showError" class="input-error-msg">High school name requires letters</div>
            </el-col>
            <el-col :sm="12" :xs="24">
              <common-prod-input type="onlySelect" inputTitle="Year">
                <template v-slot:selectInput>
                  <el-select
                    v-model="infoForm.year_of_graduation"
                    :popper-append-to-body="false"
                    placeholder=""
                    filterable
                  >
                    <el-option
                      :value="'Other/NA'"
                      :label="'Other/NA'"
                    ></el-option>
                    <el-option
                      v-for="item in 20"
                      :key="item"
                      :label="currentYear - item"
                      :value="currentYear - item"
                    >
                    </el-option>
                  </el-select>
                </template>
              </common-prod-input>
            </el-col>
          </el-row>

          <common-region-input-group @form-change="handleFormChange"/>
        </div>
        <common-prod-button :disabled="disabled" size="large" @click.native="handleLook">LOOKS GOOD</common-prod-button>
      </div>
    </common-started-layout>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import dayjs from 'dayjs';
  import _ from 'lodash';

  @Component
  export default class InfoPage extends Vue {
    originalForm = {}
    showError = false
    currentYear = dayjs().year() + 10
    infoForm = {
      first_name: '',
      last_name: '',
      high_school: '',
      year_of_graduation: '',
      city: '',
      country_id: '',
      state_id: '',
      city_id: '',
      id: ''
    }

    get disabled() {
      return this.showError;
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();
      this.originalForm = _.pick(this.$get(this.$authStore, 'user.profile'), Object.keys(this.infoForm));
      Object.assign(this.infoForm, this.originalForm);
    }

    handleChangeValue() {
      if (this.infoForm.high_school) {
        this.showError = !(/^[\sa-zA-Z]+$/.test(this.infoForm.high_school));
      } else {
        this.showError = false;
      }
    }

    routerChange() {
      const { screen_name } = this.$authStore.user;
      if (!screen_name) {
        this.$router.push({ query: { ...this.$route.query, step: 'settingNickname' } });
        return;
      }
      this.$router.replace({ name: 'home' });
    }

    handleFormChange(e) {
      const { country_id, state_id, city_id } = e;
      this.infoForm.country_id = country_id;
      this.infoForm.state_id = state_id;
      this.infoForm.city_id = city_id;
    }

    async handleLook() {
      if (this.disabled) {
        return;
      }
      // 如果是学生，需要先填写完信息点击确定了，下次才不要显示欢迎页面了
      await this.$authStore.updateIsShow('show_start_page', false);
      if (_.isEqual(this.infoForm, this.originalForm)) {
        this.routerChange();
        return;
      }
      await this.$autoLoading(this.$authStore.updatePersonalInfo({ profile_attributes: this.infoForm }));
      this.$message({ message: 'Update profile info successfully', type: 'success' });
      this.routerChange();
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    padding: 92px 0;
    font-size: 36px;
    text-align: center;
    color: var(--font-secondary-black);

    @include media-xs-only {
      padding: 40px 16px 80px;
    }

    .title {
      @include media-xs-only {
        padding: 0 54px;
        font-size: 32px;
      }
    }

    .subtitle {
      padding: 0 54px;
      margin-top: 20px;
      font-size: 24px;
    }

    .input-group {
      margin-top: 66px;
    }

    .input-error-msg {
      text-align: left;
    }
  }

  /deep/ .el-col {
    margin-bottom: 32px;
  }

  .ato-button {
    margin-top: 45px;

    @include media-xs-only {
      margin-top: 8px;
    }
  }

  /deep/ .input-wrap {
    width: 400px;

    @include media-xs-only {
      width: 100%;
    }
  }

</style>
