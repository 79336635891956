<template>
  <div>
    <div class="empty" v-if="store.isEmpty">{{ emptyTips }}</div>
    <component
      :is="authStore.user.role === 'advisor' ? 'draggable' : 'div'"
      class="list-wrapper"
      :class="authStore.user.role"
      group="schools"
      fallbackTolerance="1"
      @remove="handleRemove"
      @add="handleAdd"
    >
      <div class="item-wrapper font-regular"
        v-for="item in store.data"
        :key="item.id"
        :data-id="item.id"
      >
        <div>
          <div class="school-name text-overflow">
            <router-link :to="{name: 'school.detail', params: { id: item.id }}" class="link">{{ $get(item, 'school.name') }}</router-link>
          </div>
          <div class="flex links-wrapper">
            <router-link :to="{ name: 'tasks', query: { school_student_id_in: item.id } }" class="link">{{ item.active_tasks_count | pluralize('active assignment', true, 'No') }}</router-link>
            <router-link :to="{ name: 'files', query: { school_student_id_in: item.id } }" class="link">{{ item.documents_count | pluralize('file', true, 'No') }}</router-link>
          </div>
        </div>
        <common-prod-msg :msg-text="item.comments_count" v-if="item.comments_count"/>
      </div>
    </component>
    <template v-if="authStore.user.role === 'advisor' || isConsider">
      <div class="form flex item-center" v-if="showForm">
        <el-select
          v-model="newSchoolName"
          filterable
          remote
          placeholder="Search"
          :remote-method="handleSearchSchool"
          :loading="schoolsStore.isFetching"
          :popper-append-to-body="false"
          @focus="focusFetchData"
        >
          <el-option
            v-for="item in schoolsStore.data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button class="submit-btn" @click="handleAddSchool" :disabled="!newSchoolName">ADD</el-button>
        <i class="el-icon-close" @click="showForm = false"></i>
      </div>
      <common-prod-button border @click.native="showForm = true" v-else>ADD A SCHOOL</common-prod-button>
    </template>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import draggable from 'vuedraggable';
  import { Collection, SchoolsStore, authStore } from '@/stores';

  @Component({
    components: {
      draggable
    }
  })
  export default class SchoolLists extends Vue {
    @Prop(Collection) store
    @Prop(Boolean) isConsider

    authStore = authStore
    schoolsStore = SchoolsStore.create({ params: { is_added: false } })
    showForm = false
    newSchoolName = ''

    get emptyTips() {
      return this.authStore.isAdvisor ? 'You haven\'t added any schools yet.' : 'You haven\'t added any schools yet. Discuss it with your team!';
    }

    async focusFetchData() {
      await this.schoolsStore.fetchData({ name_cont: '' });
    }

    handleSearchSchool(keyWord) {
      this.schoolsStore.params.name_cont = keyWord;
      this.schoolsStore.fetchData();
    }

    @Vue.autoLoading
    async handleAddSchool() {
      await this.store.add(this.newSchoolName);
      this.newSchoolName = '';
      await this.schoolsStore.fetchData();
    }

    handleRemove(e) {
      this.store.removeItemById(e.item.dataset.id);
    }

    @Vue.autoLoading
    async handleAdd(e) {
      await this.store.update(e.item.dataset.id);
    }
  }
</script>

<style lang="scss" scoped>
  .empty {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: var(--font-secondary-black);
  }

  .list-wrapper {
    padding-bottom: 0;

    @include media-xs-only {
      padding-bottom: 8px;
    }

    &.advisor {
      .item-wrapper {
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }
    }

    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 21px 28px 16px 24px;
      margin-bottom: 16px;
      border-radius: 8px;
      color: var(--font-primary-white);
      background: var(--radial-gradient-blue);
      opacity: 1;

      .link {
        text-decoration: underline;
        cursor: pointer;
      }

      .school-name {
        margin-right: 50px;
        margin-bottom: 11px;
        font-size: 24px;
      }

      .links-wrapper {
        font-size: 16px;

        .link {
          margin-right: 35px;
        }
      }

      /deep/ .msg {
        --msg-color: var(--font-primary-white);
      }
    }
  }

  .form {
    padding: 20px 23px;
    background: var(--bg-primary-white);

    @include media-xs-only {
      padding: 12px;
    }

    .submit-btn {
      margin: 0 36px 0 24px;
      color: var(--font-primary-white);
      background: var(--bg-primary-red);

      @include media-xs-only {
        margin: 0 12px;
      }
    }

    .el-icon-close {
      font-size: 20px;
      color: var(--font-third-gray);
      cursor: pointer;
    }
  }

  /deep/ .el-select {
    width: 100%;

    .el-input__inner {
      padding: 10px 30px;
      border: 1px solid var(--border-secondary-gray);
      overflow: hidden;
      font-size: 1.25918rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--font-secondary-black);
      background: transparent;

      @include media-xs-only {
        padding: 10px 15px;
        font-size: 16px;
      }
    }
  }

  /deep/ .el-select-dropdown__item {
    &.hover {
      font-family: 'Neue Montreal Bold';
    }
  }
</style>
