<template>
  <div class="tasks list-page">
    <div class="page-title">{{ keyword ? `Search "${keyword}" results` : `${titleName} Assignments` }}</div>
    <div class="content">
      <div v-if="keyword && tasksStore.isEmpty" class="tip">
        {{ `No results related to "${keyword}" were found` }}
      </div>

      <template v-else>
        <div v-if="$get(settingsStore, 'college_application_timeline_pdf.url') && !$authStore.isAdvisor" class="tips-box">
          Make sure you're on track! Check your timeline:
          <span class="link" @click="handleOpenUrl">College Admissions Senior Timeline</span>
        </div>

        <common-prod-filter-groups :groups="filterGroups"/>
        <div class="list-title flex item-center content-between">
          <common-prod-button border @click.native="showAddTask = true">
            ADD AN ASSIGNMENT
          </common-prod-button>
          <div class="link" @click="handleToggleCompleted">Show {{isCompletedList ? 'Activity' : 'Completed'}} Assignments</div>
        </div>

        <template v-for="(list, title) in lists">
          <div class="list-wrap" :key="title" v-if="list.length">
            <div class="sub-title" v-if="title">{{ title }}</div>
            <div class="list-content flex item-center content-between" v-for="item in list" :key="item.id">
              <div class="list-left-wrap flex item-center">
                <common-prod-list-line
                  :time="`(Due ${$options.filters.formatTime(item.due_at)})`"
                  :text="item.name || ''"
                  :to="{name: 'task.detail', params: { id: item.id }}"
                  :msgNum="item.comments_count"
                  :taskStatus="item.status"
                  @finish="finishTask(item)"
                >
                  <template v-slot:tagSlot>
                    <div class="hidden-md-and-down flex item-center no-wrap">
                      <el-tooltip class="item" effect="dark" :content="findItemById(item.task_category_id, categoriesStore.data)" placement="top-start">
                        <common-prod-tag
                          :tagText="findItemById(item.task_category_id, categoriesStore.data)"
                          :toggle="false"
                        />
                      </el-tooltip>

                      <el-tooltip class="item" effect="dark" :content="findItemById(item.school_student_id, schoolStudentsStore.data, true)" placement="top-start">
                        <common-prod-tag
                          :tagText="findItemById(item.school_student_id, schoolStudentsStore.data, true)"
                          :toggle="false"
                        />
                      </el-tooltip>

                    </div>
                  </template>
                </common-prod-list-line>
                <div v-if="isCompletedList" @click="handleReopen(item)" class="hidden-md-and-down re-open-btn">re-open</div>
              </div>
              <div class="hidden-md-and-down assign">Assigned by {{ $get(item, 'creator.screen_name') }}</div>
            </div>
          </div>
        </template>
        <div v-if="tasksStore.isEmpty && !keyword" class="empty-wrap">{{`You have no ${emptyText} assignments`}}</div>
        <common-prod-pagination :store="tasksStore"/>
      </template>

    </div>

    <common-popup v-model="showAddTask" title="Add an assignment">
      <task-form :submit="handleAddTask" @close="showAddTask = false"/>
    </common-popup>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import TaskForm from './components/task-form.vue';
  import { categoriesStore, schoolStudentsStore, TasksStore, settingsStore } from '@/stores';
  import { getQueryArray } from '@/utils';
  import _ from 'lodash';

  @Component({
    components: {
      'task-form': TaskForm
    }
  })
  export default class Tasks extends Vue {
    showAddTask = false
    categoriesStore = categoriesStore
    schoolStudentsStore = schoolStudentsStore
    settingsStore = settingsStore

    get isCompletedList() {
      return this.$route.query.type === 'completed';
    }

    get tasksStore() {
      return TasksStore.create({ params: { completed_at_not_null: this.isCompletedList } });
    }

    get keyword() {
      return this.$route.query.keyword;
    }

    get lists() {
      return this.isCompletedList ? {
        '': this.tasksStore.data
      } : {
        'Open assignments': this.tasksStore.overdueTasks,
        'Due this week': this.tasksStore.thisWeekTasks,
        'Due later': this.tasksStore.laterTasks,
      };
    }

    get titleName() {
      if (this.isStudent) {
        return 'Your';
      } else {
        return this.$get(this.$authStore, 'user.students.length') ? `${this.$get(this.$authStore, 'user.current_role.name')}'s` : 'your student\'s';
      }
    }

    get isStudent() {
      return this.$get(this.$authStore, 'user.role') === this.$get(this.$authStore, 'user.current_role.role');
    }

    get emptyText() {
      let emptyArr = [];
      getQueryArray('task_category_id_in').forEach(value => {
        emptyArr.push(this.findItemById(Number(value), this.categoriesStore.data));
      });
      getQueryArray('school_student_id_in').forEach(value => {
        emptyArr.push(this.findItemById(Number(value), this.schoolStudentsStore.data, true));
      });
      return [...emptyArr].join(' and ');
    }

    get filterGroups() {
      const types = _.map(this.categoriesStore.data, item => {
        return {
          label: item.name,
          value: item.id
        };
      });
      const schools = _.map(this.schoolStudentsStore.data, item => {
        return {
          label: this.$get(item, 'school.name', ''),
          value: item.id
        };
      });
      return [
        {
          key: 'task_category_id',
          title: 'By Type',
          allBtn: true,
          data: types,
        },
        {
          key: 'school_student_id',
          title: 'By School',
          allBtn: true,
          data: schools,
        }
      ];
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.fetchData();
    }

    async fetchData() {
      this.tasksStore.setQueryToParams([
        'keyword',
        'task_category_id_in',
        'school_student_id_in',
      ]);
      await Promise.all([
        this.categoriesStore.tryFetchData(),
        this.schoolStudentsStore.tryFetchData(),
        this.tasksStore.fetchData(),
        this.settingsStore.tryFetchData()
      ]);
    }

    findItemById(id, list, school = false) {
      let item = list.find((item) => item.id === id);
      if (school) {
        return this.$get(item, 'school.name', '');
      } else {
        return this.$get(item, 'name', '');
      }
    }

    async finishTask(item) {
      try {
        if (this.$authStore.isAdvisor) {
          item.status = 'completed';
          await this.$request.put(`tasks/${item.id}/advisor_complete`);
          this.tasksStore.removeItemById(item.id);
        } else {
          item.status = 'wait_advisor_review';
          await this.$request.put(`tasks/${item.id}/student_complete`);
        }
      } catch (e) {
        this.$message.error(e.message);
      }
    }

    @Vue.autoLoading
    async handleAddTask(body) {
      await this.tasksStore.add(body);
    }

    handleToggleCompleted() {
      const { query } = this.$route;
      const type = this.isCompletedList ? undefined : 'completed';
      this.$router.replace({ query: { ...query, type, page: 1 } });
    }

    async handleReopen(item) {
      await this.$confirm('Are you sure you want to reopen this assignment?', 'Confirmation');
      await this.$request.put(`/tasks/${item.id}/reopen`);
      this.tasksStore.removeItemById(item.id);
    }

    /**
     * 打开外部链接
     */
    handleOpenUrl() {
      const { college_application_timeline_pdf } = settingsStore;
      window.open(college_application_timeline_pdf.url, '_blank');
    }
  }
</script>

<style lang="scss" scoped>
  .content {
    .tag-wrap {
      margin-bottom: 42px;

      .tag-container {
        margin-bottom: 13px;

        .name {
          width: 78px;
          font-size: 16px;
          color: var(--font-primary-blue);
        }

        .tag-group {
          margin-left: 30px;

          .tag {
            margin-bottom: 12px;
          }

          .tag:first-of-type {
            margin-right: 12px;
          }

          .tag + .tag {
            margin-right: 12px;
          }
        }
      }
    }

    .list-title {
      overflow: hidden;

      .link {
        float: right;
        font-size: 18px;
        text-decoration: underline;
        color: var(--font-secondary-blue);
        cursor: pointer;
      }
    }

    .tips-box {
      margin-bottom: 40px;
      font-size: 20px;

      .link {
        margin-left: 10px;
        text-decoration: underline;
        color: var(--font-seventh-purple);
        cursor: pointer;

        @include media-xs-only {
          margin-left: 0;
        }
      }
    }

    .list-wrap {
      margin-top: 34px;
      color: var(--font-seventh-purple);

      .sub-title {
        max-width: 800px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 2px solid var(--border-secondary-gray);
        font-size: 24px;
        color: var(--font-third-gray);
      }

      .list-content {
        width: 100%;
        margin-bottom: 25px;
      }

      .list-left-wrap {
        .re-open-btn {
          padding: 6px;
          border-radius: 8px;
          font-size: 18px;
          text-align: center;
          white-space: nowrap;
          color: var(--font-primary-white);
          background: var(--bg-primary-purple);
          cursor: pointer;
        }

        .tag + .tag {
          margin-left: 16px;
        }

        .tag + span {
          margin-left: 21px;
        }
      }

      .assign {
        white-space: nowrap;
        color: var(--font-primary-blue);
      }
    }

    .empty-wrap {
      margin-top: 34px;
      font-size: 20px;
      text-align: center;
    }
  }

  .tip {
    margin-bottom: 80px;
    font-size: 36px;
    color: var(--font-secondary-black);
  }
</style>
