<template>
  <div class="info-wrap personal-info">
    <el-row :gutter="30">
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          required
          inputTitle="First Name"
          placeholder="Nothing entered"
          v-model.trim="profileForm.first_name"
          @input="errorList.first_name = ''"
          @keyup.enter.native="handleFormValidator"
        />
        <div v-show="errorList.first_name" class="input-error-msg">{{ errorList.first_name }}</div>
      </el-col>
      <el-col :xs="24" :md="8" :sm="12" style="position: relative;" class="input-group">
        <common-prod-input
          required
          inputTitle="Last Name"
          placeholder="Nothing entered"
          v-model.trim="profileForm.last_name"
          @input="errorList.last_name = ''"
          @keyup.enter.native="handleFormValidator"
        />
        <div v-show="errorList.last_name" class="input-error-msg">{{ errorList.last_name }}</div>
        <!-- 上传头像 -->
        <div class="avatar-wrap flex column item-center">
          <input
            class="input-file"
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            @change="handleUploadImage"
            id="file"
          />
          <label class="file" for="file">
            <img class="avatar" :src="getAvatar" mode="widthFix" />
            <div class="img-tip">
              Upload photo
            </div>
          </label>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="30">
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          inputTitle="Nickname"
          placeholder="Nothing entered"
          v-model.trim="nickName"
          @keyup.enter.native="handleFormValidator"
        />
      </el-col>
    </el-row>

    <div v-if="!$authStore.isAdvisor">
      <el-row :gutter="30">
        <el-col :xs="24" :md="8" :sm="12" class="input-group">
          <common-prod-input
            required
            inputTitle="High School"
            placeholder="Nothing entered"
            v-model.trim="profileForm.high_school"
            @input="handleChangeSchool"
            @keyup.enter.native="handleFormValidator"
          />
          <div v-show="errorList.high_school" class="input-error-msg">{{ errorList.high_school }}</div>
        </el-col>
        <el-col :xs="24" :md="8" :sm="12" class="input-group">
          <common-prod-input type="onlySelect" inputTitle="Year of Graduation">
            <template v-slot:selectInput>
              <el-select
                v-model="profileForm.year_of_graduation"
                :popper-append-to-body="false"
                placeholder="Nothing entered"
                filterable
                @input="errorList.year_of_graduation = ''"
              >
                <el-option
                  v-for="item in 20"
                  :key="item"
                  :label="currentYear - item"
                  :value="currentYear - item"
                >
                </el-option>
              </el-select>
            </template>
          </common-prod-input>
          <div v-show="errorList.year_of_graduation" class="input-error-msg">{{ errorList.year_of_graduation }}</div>
        </el-col>
      </el-row>

      <common-region-input-group :gutter="30" :md="8" placeholder="Nothing entered" :countryRequire="true"
        @form-change="handleFormChange"
        @hide-error="errorList.country_id = ''"
      >
        <template v-slot:errorTip>
          <div v-show="errorList.country_id" class="input-error-msg">{{ errorList.country_id }}</div>
        </template>
      </common-region-input-group>
    </div>

    <el-row :gutter="30">
      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          inputTitle="Email"
          placeholder="Nothing entered"
          v-model.trim="email"
        />
      </el-col>

      <el-col :xs="24" :md="8" :sm="12" class="input-group">
        <common-prod-input
          inputTitle="Verification Code"
          placeholder="Nothing entered"
          v-model.trim="verification_code"
        />
        <common-prod-button class="send-btn" :disabled="!isUpdateEmail || disabledCodeBtn" @click.native="handleSendCode">
          <span v-show="disabledCodeBtn">
            <common-countdown
              ref="countdown"
              name="change_email"
              :duration="60000"
              :start-time="resetPasswordTime"
              @start="disabledCodeBtn = true"
              @end="disabledCodeBtn = false"
            ></common-countdown> S&nbsp;&nbsp;LEFT
          </span>
          <span v-show="!disabledCodeBtn">Send Code</span>
        </common-prod-button>
      </el-col>
    </el-row>

    <div class="footer">
      <common-prod-button
        size="large"
        @click.native="handleSaveChange()"
      >
        SAVE CHANGES
      </common-prod-button>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import { uploadFile } from '@/utils';
  import Schema from 'async-validator';
  import dayjs from 'dayjs';
  import _ from 'lodash';

  @Component
  export default class PersonalInfo extends Vue {
    profileForm = {
      first_name: '',
      last_name: '',
      high_school: '',
      year_of_graduation: '',
      country_id: '',
      state_id: '',
      city_id: '',
      id: null
    }

    showAvatar = ''
    signedId = null
    nickName = ''
    email = ''
    verification_code = ''
    currentYear = dayjs().year() + 10
    disabledCodeBtn = false
    resetPasswordTime = ''

    errorList = {
      first_name: '',
      last_name: '',
      high_school: '',
      year_of_graduation: '',
      country_id: ''
    }

    get getAvatar() {
      if (this.showAvatar) {
        return this.showAvatar;
      } else {
        return _.get(this.$authStore, 'user.avatar.url') || require('@/assets/default-avatar.png');
      }
    }

    get isStudent() {
      return this.$authStore.user.role === 'student';
    }

    get isUpdateEmail() {
      return this.email !== this.$get(this.$authStore, 'user.email');
    }

    @Vue.autoLoadingProgress
    async created() {
      await this.$authStore.tryFetchData();
      const profileAttributes = _.pick(_.get(this.$authStore, 'user.profile'), Object.keys(this.profileForm));

      Object.assign(this.profileForm, profileAttributes);

      this.email = this.$get(this.$authStore, 'user.email');
      this.nickName = this.$authStore.user.screen_name;
      this.signedId = this.$authStore.user.avatar ? this.$authStore.user.avatar.signed_id : null;
    }

    // input 框报错信息区分 blank 和 填写非字母
    handleChangeSchool() {
      this.errorList.high_school = '';
      if (this.profileForm.high_school && !(/^[\sa-zA-Z]+$/.test(this.profileForm.high_school))) {
        this.errorList.high_school = 'High school name requires letters';
      } else {
        this.errorList.high_school = false;
      }
    }

    async handleUploadImage(e) {
      if (e.target.files.length) {
        const file = e.target.files[0];
        const { url, signed_id } = await this.$autoLoading(uploadFile(file));
        this.showAvatar = url;
        this.signedId = signed_id;
      } else {
        return;
      }
    }

    async handleFormValidator() {
      const validator = new Schema({
        first_name: { required: true, message: 'First Name can\'t be blank' },
        last_name: { required: true, message: 'Last Name can\'t be blank' },
        high_school: { required: this.isStudent, message: 'High School can\'t be blank' },
        year_of_graduation: { required: this.isStudent, message: 'Year of graduation can\'t be blank' },
        country_id: { required: this.isStudent, message: 'Country can\'t be blank' }
      });

      try {
        await validator.validate(this.profileForm);
      } catch ({ errors }) {
        errors.map(err => this.errorList[err.field] = err.message);
        throw new Error();
      }
    }

    async handleSaveChange() {
      await this.handleFormValidator();

      // 新用户更新后，接口会返回新创建的profile id, 这里需要更新一次form， 避免新用户在此点击更新后报 500
      this.profileForm.id = _.get(this.$authStore, 'user.profile.id');

      await this.$autoLoading(this.$authStore.updatePersonalInfo(
      {
        profile_attributes: this.profileForm,
        screen_name: this.nickName,
        avatar: this.signedId,
        email: this.email,
        verification_code: this.verification_code
      }));

      this.$message({ message: 'The personal info is modified successfully', type: 'success' });
    }

    async handleSendCode() {
      if (this.disabledCodeBtn) {
        return;
      }
      try {
        await this.$autoLoading(this.$request.post('verification_codes', {
          target: this.email,
          event: 'change_email'
        }));
        this.$message({ message: 'Successfully sent!', type: 'success' });
        this.resetPasswordTime = Date.now();
      } catch (e) {
        this.$message.error(e.message);
      }
    }

    handleFormChange(e) {
      const { country_id, state_id, city_id } = e;
      this.profileForm.country_id = country_id;
      this.profileForm.state_id = state_id;
      this.profileForm.city_id = city_id;
    }
  }
</script>

<style lang="scss" scoped>
  .info-wrap {
    &.personal-info {
      @include media-xs-only {
        margin-top: 122px;
      }

      .send-btn {
        margin-top: 14px;
      }
    }
  }

  .avatar-wrap {
    position: absolute;
    z-index: 9;
    top: 0;
    right: -320px;
    width: 200px;

    @include media-xs-only {
      top: -240px;
      left: 30%;
    }

    .avatar {
      width: 116px;
      height: 116px;
      border: 3px solid var(--border-secondary-black);
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;

      @include media-xs-only {
        width: 80px;
        height: 80px;
      }
    }

    .img-tip {
      font-weight: 500;
      font-size: 24px;
      line-height: 46px;
      color: var(--font-primary-purple);
      cursor: pointer;

      @include media-xs-only {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  .input-file {
    display: none;
  }

  .file {
    cursor: pointer;
  }

  .footer {
    margin-bottom: 252px;
  }

  /deep/ .el-scrollbar {
    height: 256px;
  }
</style>
