import Vue from 'vue';
import './element';

Vue.use(require('./title').default);
Vue.component('common-popup', require('./popup').default);
Vue.component('common-countdown', require('./countdown').default);
// 开始页面布局
Vue.component('common-started-header', require('./started-layout/started-header.vue').default);
Vue.component('common-started-footer', require('./started-layout/started-footer.vue').default);
Vue.component('common-started-layout', require('./started-layout').default);
// common页面布局
Vue.component('common-layout', require('./common-layout').default);
// prod
Vue.component('common-prod-button', require('./prod/button.vue').default);
Vue.component('common-prod-input', require('./prod/input.vue').default);
Vue.component('common-prod-select', require('./prod/select.vue').default);
Vue.component('common-prod-upload-input', require('./prod/upload-input.vue').default);
Vue.component('common-prod-card', require('./prod/card.vue').default);
Vue.component('common-prod-guide-card', require('./prod/guide-card.vue').default);
Vue.component('common-prod-tag', require('./prod/tag.vue').default);
Vue.component('common-prod-msg', require('./prod/msg-icon.vue').default);
Vue.component('common-prod-list-line', require('./prod/list-line.vue').default);
Vue.component('common-prod-file-lists', require('./prod/file-lists.vue').default);
Vue.component('common-prod-pagination', require('./prod/pagination').default);
Vue.component('common-prod-filter-groups', require('./prod/filter-groups').default);
Vue.component('common-prod-activity-msg', require('./prod/activity-msg').default);
Vue.component('common-prod-password-group', require('./prod/password-group').default);
Vue.component('common-vditor', require('./vditor.vue').default);
Vue.component('common-msg-list', require('./prod/msg-list.vue').default);
Vue.component('common-region-input-group', require('./prod/region-input-group.vue').default);
